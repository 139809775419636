import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notification, Select, SelectProps } from "antd";
import { tagSlice } from "../../ducks/tagDuck/tagDuck";
import IReactState from "../../types/ReactState";

interface IOption {
  label: string;
  value: string;
}

interface IProps extends SelectProps {
  type: "STARLET" | "STUDIO" | "SCENE" | "OTHER" | "ALL";
}

const TagsSelector = (props: IProps) => {
  const [retry, setRetry] = useState(0);
  const dispatch = useDispatch();
  const tagState = useSelector((state: IReactState) => state.tagState);

  const { type, value, onChange } = props;
  const { tags, fetchTagsError } = tagState;

  useEffect(() => {
    if (fetchTagsError) {
      if (retry >= 5) {
        notification.error({
          message: "Something went wrong!",
          description:
            "Failed to fetch tags, please click on dropdown and retry.",
        });
      } else {
        dispatch(tagSlice.actions.fetchTagsAction({ forceRefetch: false }));
        setRetry(retry + 1);
      }
      return;
    }
    dispatch(tagSlice.actions.fetchTagsAction({ forceRefetch: false }));
  }, [dispatch, fetchTagsError, retry]);

  const options = useMemo(() => {
    return [...tags]
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter((tag) => {
        if (type === "ALL") {
          return true;
        }
        return tag.type === type;
      })
      .map((tag) => ({
        label: tag.name,
        value: tag._id,
      }));
  }, [tags, type]);

  const defaultValues = value?.map(
    ({ _id, name }: { _id: string; name: string }) => ({
      label: name,
      value: _id,
    }),
  );

  const handleChange = (values: Array<string>) => {
    const selectSet = new Set(values);
    const starlets = tagState.tags.filter(({ _id }) => selectSet.has(_id));
    if (onChange) {
      onChange(starlets, []);
    }
  };

  const filterOption = (input: string, option: IOption | undefined) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const filterSort = (optionA: IOption, optionB: IOption) =>
    (optionA?.label ?? "")
      .toLowerCase()
      .localeCompare((optionB?.label ?? "").toLowerCase());

  return (
    <Select
      showSearch
      size="large"
      mode="multiple"
      placeholder="Select tags..."
      options={options}
      value={defaultValues}
      loading={tagState.fetchTagsLoading}
      onChange={handleChange}
      filterOption={filterOption}
      filterSort={filterSort}
      dropdownRender={(menu) => {
        return (
          <>
            {menu}
            {fetchTagsError && (
              <div
                className="p-4 text-center cursor-pointer text-rose-900"
                onClick={() => setRetry(0)}
              >
                <span>Click Here To Retry</span>
              </div>
            )}
          </>
        );
      }}
    />
  );
};

export default TagsSelector;

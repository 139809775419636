import React from "react";
import { Button, Space } from "antd";
import SideBar from "./SideBar";
import { useSelector } from "react-redux";
import IReactState from "../types/ReactState";

interface IAction {
  name: string;
  onClick: () => void;
}

interface IProps {
  children?: JSX.Element;
  title: string;
  actions?: Array<IAction>;
}

const PageHeaderBar = (props: IProps) => {
  const { title, actions } = props;
  return (
    <div className="px-8 bg-black border-t-0 border-b-1 border-l-0 border-r-0 border-gray-900 border-solid">
      <div className="h-20 flex justify-between items-center ">
        <div>
          <h3 className="text-white">{title}</h3>
        </div>
        <div>
          <Space direction="horizontal">
            {actions?.map(({ name, onClick }) => {
              return (
                <Button
                  key={name}
                  size="large"
                  type="primary"
                  onClick={onClick}
                >
                  {name}
                </Button>
              );
            })}
          </Space>
        </div>
      </div>
    </div>
  );
};

const PageContainer = (props: IProps) => {
  const { title, actions, children } = props;
  const { collapsed } = useSelector((state: IReactState) => state.appState);
  let className =
    "flex flex-col flex-auto transition-all duration-500 ease-out";
  if (collapsed) {
    className = `${className} ml-20`;
  } else {
    className = `${className} ml-52`;
  }

  return (
    <div className="flex h-screen">
      <SideBar />
      <div className={className}>
        <PageHeaderBar title={title} actions={actions} />
        <div className="flex-auto p-8 overflow-scroll overscroll-contain">
          {children}
        </div>
      </div>
    </div>
  );
};

export default PageContainer;

import { Space, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import PageContainer from "../../components/PageContainer";

const threads = [
  {
    title: "General Discussion",
    description: "Thread to discuss anything",
  },
  {
    title: "Request To Create",
    description: "Ask to create new starlets, studios or tags",
  },
  {
    title: "About The Tool",
    description: "All about how to use this tool",
  },
  {
    title: "Report Bugs",
    description: "Post bugs here",
  },
];
const Discussion = () => {
  return (
    <PageContainer title="Discussion">
      <div>
        <div>
          <Space direction="vertical" size="middle" className="flex">
            {threads.map((thread) => {
              return (
                <Link key={thread.title} to="/">
                  <div className="px-6 pb-4 border border-solid border-gray-800 rounded-lg">
                    <Typography.Title level={4}>
                      {thread.title}
                    </Typography.Title>
                    <Typography.Text>{thread.description}</Typography.Text>
                  </div>
                </Link>
              );
            })}
          </Space>
        </div>
        <h1 className="text-center text-gray-700">
          Discussion Threads Coming Soon!
        </h1>
        <p className="text-center text-gray-700">
          1. Display all about how to use this tool
          <br />
          2. Threads to ask to create new starlets, studios and tags
          <br />
          let user fill the form and add status with approve/reject
          <br />
          3. General Discussion Thread
          <br />
          4. Report Bugs
        </p>
      </div>
    </PageContainer>
  );
};

export default Discussion;

import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../ducks/sagas";
import rootReducer from "./reducers";

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

export const configureReduxStore = () => {
  const store = configureStore({
    middleware,
    reducer: rootReducer,
    devTools: true,
  });
  sagaMiddleware.run(rootSaga);
  return store;
};

export default configureReduxStore;

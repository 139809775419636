import { createSlice } from "@reduxjs/toolkit";
import { all, call, put, StrictEffect, takeLatest } from "redux-saga/effects";
import AuthAPI from "../../api/AuthAPI";
import { IUser } from "../../types/User";
import { userSlice } from "../userDuck/userDuck";
import AuthState, { LoginData } from "./types";
import { captureSentryException } from "../../config/Sentry";
import { AxiosError } from "axios";
import Firebase from "../../config/Firebase";

const initialState: AuthState = {
  user: null,
  token: null,
  isAuthenticated: false,
  loginLoading: false,
  loginSuccess: false,
  loginError: false,

  userMeLoading: true,
  userMeSuccess: false,
  userMeError: false,
};

export const authSlice = createSlice({
  initialState,
  name: "authSlice",
  reducers: {
    userLoginAction: (state, action: { payload: LoginData }) => {
      state.loginLoading = true;
      state.loginError = false;
      state.loginSuccess = false;
      state.userMeSuccess = false;
      state.userMeError = false;
    },
    userLoginSuccessAction: (state, action: { payload: IUser }) => {
      state.loginLoading = false;
      state.userMeLoading = false;
      state.loginSuccess = true;
      state.user = action.payload;
      state.token = action.payload.token;
      state.isAuthenticated = true;
    },
    userLoginErrorAction: (state) => {
      state.loginLoading = false;
      state.userMeLoading = false;
      state.loginError = true;
    },
    userMeAction: (state) => {
      state.userMeLoading = true;
      state.userMeSuccess = false;
      state.userMeError = false;
    },
    userMeSuccessAction: (state, action: { payload: IUser }) => {
      state.userMeLoading = false;
      state.userMeSuccess = true;
      state.token = action.payload.token;
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    userMeErrorAction: (state) => {
      state.userMeLoading = false;
      state.userMeError = true;
    },
    userLogoutAction: () => {
      //
    },
    userLogoutSuccessAction: (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
    },
    userLogoutErrorAction: () => {
      //
    },
  },
});

function* userLoginSaga(action: {
  payload: LoginData;
}): Generator<StrictEffect, void, IUser> {
  try {
    const user = yield call(AuthAPI.login, action.payload);
    const firebase = new Firebase();
    firebase.init();
    yield call(firebase.authenticate, user.firebaseToken);
    localStorage.setItem("user-token", user.token);
    yield put(authSlice.actions.userLoginSuccessAction(user));
    yield put(userSlice.actions.fetchUserProfileAction());
  } catch (error) {
    yield put(authSlice.actions.userLoginErrorAction());
    captureSentryException("SagaError", error as Error, {
      Saga: "UserLoginSaga",
    });
  }
}

function* userMeSaga(): Generator<StrictEffect, void, IUser> {
  try {
    const user = yield call(AuthAPI.me);
    const firebase = new Firebase();
    firebase.init();
    yield call(firebase.authenticate, user.firebaseToken);
    localStorage.setItem("user-token", user.token);
    yield put(userSlice.actions.fetchUserProfileAction());
    yield put(authSlice.actions.userMeSuccessAction(user));
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response?.status === 403) {
      localStorage.removeItem("user-token");
    }
    yield put(authSlice.actions.userMeErrorAction());
    captureSentryException("SagaError", error as Error, {
      Saga: "UserMeSaga",
    });
  }
}

function* userLogoutSaga(): Generator<StrictEffect> {
  try {
    localStorage.removeItem("user-token");
    yield put(authSlice.actions.userLogoutSuccessAction());
  } catch (error) {
    yield put(authSlice.actions.userLoginErrorAction());
    captureSentryException("SagaError", error as Error, {
      Saga: "UserLogoutSaga",
    });
  }
}

export function* watcherSaga() {
  yield all([takeLatest(authSlice.actions.userLoginAction, userLoginSaga)]);
  yield all([takeLatest(authSlice.actions.userMeAction, userMeSaga)]);
  yield all([takeLatest(authSlice.actions.userLogoutAction, userLogoutSaga)]);
}

import moment from "moment";

export class DateUtils {
  public static readonly dateRegex = new RegExp(
    "^([0]?[1-9]|[1-2]\\d|3[0-1])(\\s)(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)(\\s)[1-2]\\d{3}$",
  );

  public static isValid(dateString: string): boolean {
    return (
      DateUtils.dateRegex.test(dateString) &&
      moment(dateString, "DD MMM YYYY").isValid()
    );
  }

  public static getCurrentDate(): string {
    return moment().format("DD MMM YYYY");
  }

  public static getFormattedDate(date: string | Date): string {
    return moment(date).format("DD MMM YYYY");
  }

  public static handleDates(body: any) {
    if (body === null || body === undefined) {
      return body;
    }

    if (Array.isArray(body)) {
      const array = body as Array<object>;
      array.forEach((data) => {
        DateUtils.handleDates(data);
      });
    }

    if (typeof body !== "object") {
      return;
    }
    for (const key of Object.keys(body)) {
      const value = body[key];
      if (["createdAt", "updatedAt"].includes(key)) {
        body[key] = new Date(value);
      } else if (typeof value === "object") DateUtils.handleDates(value);
    }
  }
}

export default DateUtils;

import React from "react";
import { Select, SelectProps } from "antd";

const roleOptions = [
  {
    label: "Member",
    value: "MEMBER",
  },
  {
    label: "Elite Member",
    value: "ELITE_MEMBER",
  },
  {
    label: "Collaborator",
    value: "COLLABORATOR",
  },
];

const UserRoleSelector = (props: SelectProps) => {
  return (
    <Select
      size="large"
      placeholder="Select user role..."
      options={roleOptions}
      {...props}
    />
  );
};

export default UserRoleSelector;

import Axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import DateUtils from "../utils/DateUtils";

export default class API {
  readonly baseURL: string;
  readonly axios: AxiosInstance;

  constructor(baseURL?: string) {
    this.baseURL = baseURL || (process.env.REACT_APP_API_URL as string);

    this.axios = Axios.create({
      baseURL: `${this.baseURL}/api`,
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.axios.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const token = window.localStorage.getItem("user-token");
        if (token) {
          config.headers["Authorization"] = token;
        }
        return config;
      },
    );

    this.axios.interceptors.response.use((response: AxiosResponse) => {
      DateUtils.handleDates(response.data);
      return response;
    });
  }

  callApi({ method = "GET", ...rest }) {
    return this.axios({ method, ...rest });
  }
}

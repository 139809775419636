import React, { useMemo } from "react";
import { Col, Row, Spin } from "antd";
import { BACKGROUNDS_IMAGES } from "../consts/consts";
import Utils from "../utils/Utils";

const PageSpinner = () => {
  const backgroundImage = useMemo(() => {
    return Utils.shuffleArray(BACKGROUNDS_IMAGES.loading).at(0);
  }, []);

  return (
    <div>
      <div className="absolute flex w-full h-full top-0 bottom-0 left-0 right-0 z-10">
        <img
          className="w-full object-cover object-center bg-black"
          src={backgroundImage}
          alt=""
        />
      </div>
      <div className="relative z-20">
        <Row justify="end" align="middle" className="h-screen">
          <Col md={10} className="text-rose-700 text-center">
            <h1 className="text-6xl fw-extrabold">RedPics</h1>
            <Spin size="large" />
            <h5 className="text-base">Loading Please Wait...</h5>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PageSpinner;

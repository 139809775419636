import { createSlice } from "@reduxjs/toolkit";
import { all, call, put, StrictEffect, takeLatest } from "redux-saga/effects";
import UserAPI from "../../api/UsersAPI";
import { captureSentryException } from "../../config/Sentry";
import {
  CreateUserData,
  ICreateUserRequestData,
  IUser,
} from "../../types/User";
import UserState from "./types";

const initialState: UserState = {
  createUserLoading: false,
  createUserProfileSuccess: false,
  createUserProfileError: false,

  userProfile: null,
  fetchUserProfileLoading: false,
  fetchUserProfileSuccess: false,
  fetchUserProfileError: false,

  users: [],
  fetchUsersLoading: false,
  fetchUsersSuccess: false,
  fetchUsersError: false,
};

export const userSlice = createSlice({
  initialState,
  name: "userSlice",
  reducers: {
    createUserAction: (state, { payload }) => {
      state.createUserLoading = true;
      state.createUserProfileSuccess = false;
      state.createUserProfileError = false;
    },
    createUserSuccessAction: (state, { payload }) => {
      state.createUserLoading = false;
      state.createUserProfileSuccess = true;
    },
    createUserErrorAction: (state) => {
      state.createUserLoading = false;
      state.createUserProfileError = true;
    },
    fetchUserProfileAction: (state) => {
      state.fetchUserProfileLoading = true;
      state.fetchUserProfileSuccess = false;
      state.fetchUserProfileError = false;
    },
    fetchUserProfileSuccessAction: (state, { payload }) => {
      state.fetchUserProfileLoading = false;
      state.fetchUserProfileSuccess = true;
      state.userProfile = payload;
    },
    fetchUserProfileErrorAction: (state) => {
      state.fetchUserProfileLoading = false;
      state.fetchUserProfileError = true;
    },
    fetchUsesAction: (state) => {
      state.users = [];
      state.fetchUsersLoading = true;
      state.fetchUsersSuccess = false;
      state.fetchUsersError = false;
    },
    fetchUsesSuccessAction: (state, { payload }) => {
      state.fetchUsersLoading = false;
      state.fetchUsersSuccess = true;
      state.users = payload;
    },
    fetchUsesErrorAction: (state) => {
      state.fetchUsersLoading = false;
      state.fetchUsersError = true;
    },
  },
});

function* createUserSaga(action: {
  payload: CreateUserData;
}): Generator<StrictEffect, void, IUser> {
  const { username, email, password, avatar, userRole } = action.payload;
  const userData: ICreateUserRequestData = {
    username,
    email,
    password,
    avatar,
    userRole,
  };
  try {
    const user = yield call(UserAPI.createUser, userData);
    yield put(userSlice.actions.createUserSuccessAction(user));
  } catch (error) {
    yield put(userSlice.actions.createUserErrorAction());
    captureSentryException("SagaError", error as Error, {
      Saga: "CreateUserSaga",
    });
  }
}

function* fetchUserProfileSaga(): Generator<StrictEffect, void, IUser> {
  try {
    const user = yield call(UserAPI.fetchUserProfile);
    yield put(userSlice.actions.fetchUserProfileSuccessAction(user));
  } catch (error) {
    yield put(userSlice.actions.fetchUserProfileErrorAction());
    captureSentryException("SagaError", error as Error, {
      Saga: "FetchUserProfileSaga",
    });
  }
}

function* fetchUsersSaga(): Generator<StrictEffect, void, Array<IUser>> {
  try {
    const user = yield call(UserAPI.fetchUsers);
    yield put(userSlice.actions.fetchUsesSuccessAction(user));
  } catch (error) {
    yield put(userSlice.actions.fetchUsesErrorAction());
    captureSentryException("SagaError", error as Error, {
      Saga: "FetchUsersSaga",
    });
  }
}

export function* watcherSaga() {
  yield all([
    takeLatest(userSlice.actions.createUserAction, createUserSaga),
    takeLatest(userSlice.actions.fetchUserProfileAction, fetchUserProfileSaga),
    takeLatest(userSlice.actions.fetchUsesAction, fetchUsersSaga),
  ]);
}

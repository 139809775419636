import { createSlice } from "@reduxjs/toolkit";
import { all, call, StrictEffect, takeLatest } from "redux-saga/effects";
import OtherAPI from "../../api/OtherAPI";
import { IUser } from "../../types/User";
import IAppState from "./types";
import { captureSentryException } from "../../config/Sentry";

const initialState: IAppState = {
  collapsed: true,
};

export const appSlice = createSlice({
  initialState,
  name: "appSlice",
  reducers: {
    toggleSidebar: (state) => {
      state.collapsed = !state.collapsed;
    },
    pingAction: () => {
      //
    },
  },
});

function* pingSaga(): Generator<StrictEffect, void, IUser> {
  try {
    yield call(OtherAPI.ping);
  } catch (error) {
    captureSentryException("SagaError", error as Error, {
      Saga: "PingSaga",
    });
  }
}

export function* watcherSaga() {
  yield all([takeLatest(appSlice.actions.pingAction, pingSaga)]);
}

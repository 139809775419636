import config from "../config/config";

export const FILE_HOSTS: {
  [key: string]: string;
} = {
  K2S: "K2S",
  FBOOM: "Fboom",
  TEZFILES: "TezFiles",
  ANONFILES: "AnonFiles",
  DEPOSITFILES: "DepositFiles",
  "DROP-DOWNLOAD": "DropDownload",
  FILEJOKER: "FileJoker",
  FILEFACTORY: "FileFactory",
  RAPIDGATOR: "RapidGator",
  MEXASHARE: "Mexs.sh",
  BUNKR: "Bunkr.su",
  MEDIAFIRE: "MediaFire",
  MEGA: "Mega.nz",
};

export const FILE_HOSTS_SHORT_NAME: {
  [key: string]: string;
} = {
  K2S: "K2S",
  FBOOM: "FB",
  TEZFILES: "TF",
  ANONFILES: "AF",
  DEPOSITFILES: "DF",
  "DROP-DOWNLOAD": "DD",
  FILEJOKER: "FJ",
  FILEFACTORY: "FF",
  RAPIDGATOR: "RG",
  MEXASHARE: "MEX",
  BUNKR: "BKR",
  MEDIAFIRE: "MF",
  MEGA: "MGA",
};

export const REMARKS: {
  [key: string]: string;
} = {
  PRE_RELEASE: "Pre-Release",
  HIGH_RESOLUTION: "High Resolution",
  MEDIUM_RESOLUTION: "Medium Resolution",
};

export const CATEGORIES: {
  [key: string]: string;
} = {
  HARDCORE: "Hardcore Collection",
  SOFTCORE: "Softcore Collection",
  LESBIAN: "Lesbian Collection",
  UNCATEGORIZED: "Uncategorized",
  JAPANESE: "Japanese Collection",
  KOREAN: "Korean Collection",
  CHINESE: "Chinese Collection",
  VIETNAMESE: "Vietnamese Collection",
};

export const POST_STATUS_LABEL_MAP: {
  [key: string]: string;
} = {
  NEW_CREATED: "New Created",
  RELEASE_READY: "Release Ready",
  REJECTED: "Rejected",
  REVIEWED: "Reviewed",
  UPDATED: "Updated",
  RELEASED: "Released",
};

export const BACKGROUNDS_IMAGES_MAP: {
  [key: string]: {
    [key: string]: Array<string>;
  };
} = {
  production: {
    loginPage: ["/images/001.webp"],
    noFoundPage: ["/images/003.webp"],
    errorPage: ["/images/004.webp"],
    loading: ["/images/002.webp"],
  },
  development: {
    loginPage: [
      "https://images.pexels.com/photos/221125/pexels-photo-221125.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    ],
    noFoundPage: [
      "https://images.pexels.com/photos/221125/pexels-photo-221125.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    ],
    errorPage: [
      "https://images.pexels.com/photos/221125/pexels-photo-221125.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    ],
    loading: [
      "https://images.pexels.com/photos/221125/pexels-photo-221125.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    ],
  },
  test: {
    loginPage: ["https://ik.imagekit.io/redpicstest/backgrounds/03.jpg"],
    noFoundPage: ["https://ik.imagekit.io/redpicstest/backgrounds/01.jpg"],
    errorPage: ["https://ik.imagekit.io/redpicstest/backgrounds/02.jpg"],
    loading: ["https://ik.imagekit.io/redpicstest/backgrounds/04.webp"],
  },
};

export const BACKGROUNDS_IMAGES = BACKGROUNDS_IMAGES_MAP["production"];

export const BEFORE_UNLOAD_MESSAGE = "Are you sure you want to leave?";

import React from "react";
import PageContainer from "../components/PageContainer";

const Notes = () => {
  return (
    <PageContainer title="Notes">
      <div>
        <h1 className="text-center text-gray-700">Note Coming Soon!</h1>
        <p className="text-center text-gray-700">
          1. Page to show release notes
          <br />
          2. Posts release today and past 1 week
          <br />
          3. Reject Posts with message
          <br />
          4. Tool new features
          <br />
          5. Tool bug fixes
        </p>
      </div>
    </PageContainer>
  );
};

export default Notes;

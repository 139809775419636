import React, { useEffect, useState } from "react";
import { Row, Col, Button, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "../components/PageContainer";
import { authSlice } from "../ducks/authDuck/authDuck";
import { userSlice } from "../ducks/userDuck/userDuck";
import IReactState from "../types/ReactState";
import ChangePasswordModal from "../components/modals/ChangePasswordModal";
import DateUtils from "../utils/DateUtils";

const Profile = () => {
  const dispatch = useDispatch();
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [openEditAvatarModal, setopenEditAvatarModal] = useState(false);

  const { userProfile } = useSelector((state: IReactState) => state.userState);

  const joinDate = DateUtils.getFormattedDate(`${userProfile?.createdAt}`);

  useEffect(() => {
    dispatch(userSlice.actions.fetchUserProfileAction());
  }, [dispatch]);

  const handleOnUpdateAvatar = () => {
    //
  };

  const changePassword = (values: any) => {
    console.log("changePassword", values);
  };

  const onClickLogout = () => {
    dispatch(authSlice.actions.userLogoutAction());
  };

  const actions = [
    {
      name: "Edit Avatar",
      onClick: () => null,
    },
    {
      name: "Change Password",
      onClick: () => setOpenChangePasswordModal(true),
    },
  ];

  return (
    <PageContainer title="Profile" actions={actions}>
      <Row justify="center">
        <Col md="auto">
          <div className="text-center">
            <div>
              <img
                className="w-52 h-52 rounded-full border-1 border-solid border-rose-600"
                src={userProfile?.avatar}
                alt={userProfile?.username}
              />
            </div>
            <div className="text-rose-600">
              <h1>{userProfile?.username}</h1>
              <h4 className="text-muted">{userProfile?.userRole}</h4>
              <p className="text-gray-600">Joined On: {joinDate}</p>
            </div>
          </div>
          <div className="text-gray-600">
            <div className="flex justify-around">
              <div className="text-center shadow-lg p-3 rounded">
                <h3>{userProfile?.postsCount}</h3>
                <h5>Posts Created</h5>
              </div>
              <div className="text-center shadow-lg p-3 rounded">
                <h3>{userProfile?.imagesCount}</h3>
                <h5>Images Posted</h5>
              </div>
              <div className="text-center shadow-lg p-3 rounded">
                <h3>{userProfile?.tagsCount}</h3>
                <h5>Tags Created</h5>
              </div>
              <div className="text-center shadow-lg p-3 rounded">
                <h3>{userProfile?.starletsCount}</h3>
                <h5>Starlets Created</h5>
              </div>
              <div className="text-center shadow-lg p-3 rounded">
                <h3>{userProfile?.studiosCount}</h3>
                <h5>Studios Created</h5>
              </div>
            </div>
          </div>
          <Divider />
          <div className="flex justify-center">
            <div>
              <Button size="large" onClick={onClickLogout}>
                Log Out
              </Button>
            </div>
          </div>
        </Col>

        <ChangePasswordModal
          title="Change Password"
          open={openChangePasswordModal}
          onCancel={() => setOpenChangePasswordModal(false)}
          onFinish={changePassword}
        />
      </Row>
    </PageContainer>
  );
};

export default Profile;

import React, { useEffect, useState } from "react";
import Firebase from "../config/Firebase";
import { onValue, push, ref, remove } from "firebase/database";
import { IDraftPost } from "../types/Post";
import { Button, Col, Empty, Row, Space, Spin } from "antd";

interface IProps {
  setDraftPost: (draftPost: IDraftPost) => void;
}

const DraftPosts = (props: IProps) => {
  const { setDraftPost } = props;
  const [loading, setLoading] = useState(false);
  const [archiveLoading, setArchiveLoading] = useState<{
    [key: string]: boolean;
  }>({});

  const [draftPosts, setDraftPosts] = useState<Array<IDraftPost>>([]);

  useEffect(() => {
    setLoading(true);
    const firebase = new Firebase();
    const db = firebase.getDatabase();
    const activePhotosets = ref(db, "Photosets/Active");
    const unsubscribe = onValue(activePhotosets, (snapshot) => {
      const posts: Array<IDraftPost> = [];
      snapshot.forEach((doc) => {
        if (doc.val()) {
          posts.push({
            ...doc.val(),
            postId: doc.key,
          });
        }
      });
      setLoading(false);
      setDraftPosts(posts);
    });
    return () => unsubscribe();
  }, []);

  const onClickAutoFill = (post: IDraftPost) => {
    setDraftPost(structuredClone(post));
  };

  const onClickArchive = (post: IDraftPost) => {
    setArchiveLoading({ ...archiveLoading, [post.postId]: true });
    const firebase = new Firebase();
    const db = firebase.getDatabase();
    const archivedPhotosets = ref(db, "Photosets/Archived");
    push(archivedPhotosets, post);
    const currentPost = ref(db, `Photosets/Active/${post.postId}`);
    remove(currentPost).finally(() => {
      setArchiveLoading({ ...archiveLoading, [post.postId]: false });
    });
  };

  return (
    <Row className="h-[500px] overflow-scroll">
      {loading && (
        <div className="flex h-full w-full justify-center items-center">
          <div className="text-center">
            <Spin size="large" />
            <p>Loading...</p>
          </div>
        </div>
      )}

      {!loading && (
        <Col span={24}>
          <Space className="flex" size="small" direction="vertical">
            {draftPosts.map((post, idx) => (
              <>
                <Row>
                  <Col span={3}>Photoset Name</Col>
                  <Col>{post.photosetName}</Col>
                </Row>
                <Row>
                  <Col span={3}>Image Width</Col>
                  <Col>{post.imageWidth}</Col>
                </Row>
                <Row>
                  <Col span={3}>Image Height</Col>
                  <Col>{post.imageHeight}</Col>
                </Row>
                <Row>
                  <Col span={3}>Images Count</Col>
                  <Col>{post.imagesCount}</Col>
                </Row>
                <Row>
                  <Col span={3}>Zip Size</Col>
                  <Col>{post.fileSize}</Col>
                </Row>
                <Row>
                  <Col span={3}>Gallery Link</Col>
                  <Col>
                    <a
                      href={post.imxGalleryLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {post.imxGalleryLink}
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>Downloads</Col>
                  <Col>
                    <Space>
                      {Object.entries(post.downloads).map(([fileHost, url]) => (
                        <div key={fileHost}>
                          <a href={url} target="_blank" rel="noreferrer">
                            {fileHost}
                          </a>
                        </div>
                      ))}
                    </Space>
                  </Col>
                </Row>
                <Row justify="center" gutter={10}>
                  <Col span={6}>
                    <Button
                      block
                      size="large"
                      loading={archiveLoading[post.postId]}
                      onClick={() => onClickArchive(post)}
                    >
                      Archive
                    </Button>
                  </Col>
                  <Col span={6}>
                    <Button
                      block
                      size="large"
                      type="primary"
                      onClick={() => onClickAutoFill(post)}
                    >
                      Auto Fill
                    </Button>
                  </Col>
                </Row>
              </>
            ))}
          </Space>
        </Col>
      )}

      {!loading && !draftPosts.length && (
        <div className="flex h-full w-full justify-center items-center">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
    </Row>
  );
};

export default DraftPosts;

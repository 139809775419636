import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Input, notification, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "../../components/PageContainer";
import StudioSelector from "../../components/selectors/StudioSelector";
import { studioSlice } from "../../ducks/studioDuck/studioDuck";
import IReactState from "../../types/ReactState";
import { CreateStudioData } from "../../types/Studio";
import CategorySelector from "../../components/selectors/CategorySelector";
import ResetSwitch from "../../components/ResetSwitch";
import ValidationUtils from "../../utils/ValidationUtils";

const StudiosCreate = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [resetOnSuccess, setResetOnSuccess] = useState(true);
  const studioState = useSelector((state: IReactState) => state.studioState);

  const {
    createStudioLoading,
    createStudioSuccess,
    createStudioError,
    createStudioErrorMessage,
  } = studioState;

  useEffect(() => {
    if (createStudioSuccess) {
      notification.success({
        message: "Studio Created",
        description: `${form.getFieldValue("name")} successfully created.`,
      });
      if (resetOnSuccess) {
        form.resetFields();
      }
    }
    if (createStudioError) {
      notification.error({
        message: "Create Studio Failed",
        description:
          createStudioErrorMessage ||
          "Something went wrong, failed to create studio.",
      });
    }
    return () => {
      dispatch(studioSlice.actions.createStudioResetAction());
    };
  }, [
    dispatch,
    form,
    createStudioSuccess,
    createStudioError,
    createStudioErrorMessage,
    resetOnSuccess,
  ]);

  const onFinish = (studioData: CreateStudioData) => {
    const {
      defaultValues: { imageWidth = 0, imageHeight = 0 },
    } = studioData;

    if (
      imageWidth &&
      imageHeight &&
      Math.ceil(imageWidth) < Math.ceil(imageHeight)
    ) {
      notification.error({
        message: "Images Resolution Error",
        description: "Images width should be greater than height.",
      });
      return;
    }
    dispatch(
      studioSlice.actions.createStudioAction({
        ...studioData,
        defaultValues: {
          imageWidth,
          imageHeight,
        },
      }),
    );
  };

  return (
    <PageContainer title="Create Studio">
      <div>
        <Row justify="center">
          <Col span={20}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
              requiredMark={false}
            >
              <Form.Item
                label="Studio Name"
                name="name"
                rules={[
                  { required: true, message: "Studio name is required." },
                  {
                    validateTrigger: "submit",
                    validator: (rule, value) => {
                      if (!value || ValidationUtils.isValidName(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Name must only contain alpha-numeric characters.",
                      );
                    },
                  },
                ]}
              >
                <Input size="large" placeholder="Studio name" />
              </Form.Item>
              <Form.Item label="Primary Content Type" name="primaryContent">
                <CategorySelector
                  onChange={(type) => {
                    form.setFieldValue("primaryContent", type);
                  }}
                />
              </Form.Item>

              <Form.Item label="Owner Studio" name="ownerStudio">
                <StudioSelector
                  type="OWNERS"
                  onChange={(type) => {
                    form.setFieldValue("ownerStudio", type);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Studio Website"
                name="studioWebsite"
                rules={[
                  {
                    validateTrigger: "submit",
                    validator: (rule, value) => {
                      if (!value || ValidationUtils.isValidUrl(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Studio website must be a valid url.",
                      );
                    },
                  },
                ]}
              >
                <Input size="large" placeholder="https://www.redpics.top" />
              </Form.Item>

              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item
                    label="Default Images Width"
                    name={["defaultValues", "imageWidth"]}
                  >
                    <InputNumber
                      size="large"
                      min={1}
                      step={1}
                      addonAfter="Pixels"
                      placeholder="Image width ie. 3000"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Default Images Height"
                    name={["defaultValues", "imageHeight"]}
                  >
                    <InputNumber
                      size="large"
                      min={1}
                      step={1}
                      addonAfter="Pixels"
                      placeholder="Image height ie. 2000"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <ResetSwitch setReset={setResetOnSuccess} />
              <Row gutter={10} justify="center">
                <Col span={8}>
                  <Form.Item>
                    <Button
                      block
                      size="large"
                      disabled={createStudioLoading}
                      onClick={() => form.resetFields()}
                    >
                      Reset
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item>
                    <Button
                      block
                      size="large"
                      type="primary"
                      htmlType="submit"
                      disabled={createStudioLoading}
                      loading={createStudioLoading}
                    >
                      {createStudioLoading
                        ? "Creating Studio"
                        : "Create Studio"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </PageContainer>
  );
};

export default StudiosCreate;

import {
  ICreateStarletRequestData,
  IStarlet,
  IUpdateStarletRequestData,
} from "../types/Starlet";
import API from "./API";

const api = new API();

export const createStarlet = async (
  starletData: ICreateStarletRequestData,
): Promise<IStarlet> => {
  return api
    .callApi({
      url: "/starlets",
      method: "POST",
      data: starletData,
    })
    .then(({ data }) => data.data);
};

export const fetchStarlets = async (): Promise<Array<IStarlet>> => {
  return api
    .callApi({
      url: "/starlets",
      method: "GET",
    })
    .then(({ data }) => data.data);
};

export const fetchStarletById = async (
  starletId: string,
): Promise<Array<IStarlet>> => {
  return api
    .callApi({
      url: `/starlets/${starletId}`,
      method: "GET",
    })
    .then(({ data }) => data.data);
};

export const updateStarletById = async (
  starletId: string,
  starletData: IUpdateStarletRequestData,
): Promise<IStarlet> => {
  return api
    .callApi({
      url: `/starlets/${starletId}`,
      method: "PUT",
      data: starletData,
    })
    .then(({ data }) => data.data);
};

const StarletsAPI = {
  createStarlet,
  fetchStarlets,
  fetchStarletById,
  updateStarletById,
};

export default StarletsAPI;

import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import IReactState from "../types/ReactState";

interface IProps {
  children: JSX.Element;
}

function PublicRoute({ children }: IProps): JSX.Element {
  const location = useLocation();

  const authState = useSelector((state: IReactState) => state.authState);
  const userToken = localStorage.getItem("user-token");

  if (authState.isAuthenticated) {
    return (
      <Navigate to="/dashboard" replace={true} state={{ from: location }} />
    );
  }

  if (userToken) {
    return <Navigate to="/" replace={true} state={{ from: location }} />;
  }

  return children;
}

export default PublicRoute;

import { AxiosResponse } from "axios";
import API from "./API";

const api = new API();

export const ping = async (): Promise<AxiosResponse> => {
  return api
    .callApi({
      url: "/ping",
      method: "GET",
    })
    .then(({ data }) => data.data);
};

const OtherAPI = {
  ping,
};

export default OtherAPI;

import { createSlice } from "@reduxjs/toolkit";
import { all, call, put, StrictEffect, takeLatest } from "redux-saga/effects";
import DashboardAPI from "../../api/PageViewAPI";
import { IDashboardStats } from "../../types/Dashboard";
import DashboardState from "./types";
import { captureSentryException } from "../../config/Sentry";

const initialState: DashboardState = {
  dashboardStats: null,
  fetchStatsLoading: false,
  fetchStatsSuccess: false,
  fetchStatsError: false,

  pageViewsStats: null,
  fetchPageViewStatsLoading: false,
  fetchPageViewStatsSuccess: false,
  fetchPageViewStatsError: false,
};

export const dashboardSlice = createSlice({
  initialState,
  name: "postSlice",
  reducers: {
    fetchStatsAction: (state) => {
      state.dashboardStats = null;
      state.fetchStatsLoading = true;
      state.fetchStatsSuccess = false;
      state.fetchStatsError = false;
    },
    fetchStatsSuccessAction: (state, { payload }) => {
      state.fetchStatsLoading = false;
      state.fetchStatsSuccess = true;
      state.dashboardStats = payload;
    },
    fetchStatsErrorAction: (state) => {
      state.fetchStatsLoading = false;
      state.fetchStatsError = true;
    },

    fetchPageViewStatsAction: (state) => {
      state.pageViewsStats = null;
      state.fetchPageViewStatsLoading = true;
      state.fetchPageViewStatsSuccess = false;
      state.fetchPageViewStatsError = false;
    },
    fetchPageViewStatsSuccessAction: (state, { payload }) => {
      state.fetchPageViewStatsLoading = false;
      state.fetchPageViewStatsSuccess = true;
      state.pageViewsStats = payload;
    },
    fetchPageViewStatsErrorAction: (state) => {
      state.fetchPageViewStatsLoading = false;
      state.fetchPageViewStatsError = true;
    },
  },
});

function* fetchStatsSaga(): Generator<StrictEffect, void, IDashboardStats> {
  try {
    const post = yield call(DashboardAPI.fetchStats);
    yield put(dashboardSlice.actions.fetchStatsSuccessAction(post));
  } catch (error) {
    yield put(dashboardSlice.actions.fetchStatsErrorAction());
    captureSentryException("SagaError", error as Error, {
      Saga: "FetchStatsSaga",
    });
  }
}

function* fetchPageViewStatsSaga(): Generator<
  StrictEffect,
  void,
  IDashboardStats
> {
  try {
    const stats = yield call(DashboardAPI.fetchStats);
    yield put(dashboardSlice.actions.fetchPageViewStatsSuccessAction(stats));
  } catch (error) {
    yield put(dashboardSlice.actions.fetchPageViewStatsErrorAction());
    captureSentryException("SagaError", error as Error, {
      Saga: "FetchPageViewStatsSaga",
    });
  }
}

export function* watcherSaga() {
  yield all([
    takeLatest(dashboardSlice.actions.fetchStatsAction, fetchStatsSaga),
    takeLatest(
      dashboardSlice.actions.fetchPageViewStatsAction,
      fetchPageViewStatsSaga,
    ),
  ]);
}

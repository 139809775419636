import React from "react";
import { Select, SelectProps } from "antd";

const fileSizeOptions = [
  {
    label: "MB",
    value: "MB",
  },
  {
    label: "GB",
    value: "GB",
  },
];

const FileSizeSelector = (props: SelectProps) => {
  return (
    <Select
      size="large"
      placeholder="MB"
      options={fileSizeOptions}
      {...props}
    />
  );
};

export default FileSizeSelector;

import React, { MouseEvent } from "react";
import { Row, Col, Modal, Input, Form, ModalProps } from "antd";

interface IProps extends ModalProps {
  onFinish: (values: any) => void;
}

const ChangePasswordModal = (props: IProps) => {
  const [form] = Form.useForm();
  const { onCancel, onFinish } = props;

  const handleOnCancel = (event: MouseEvent<HTMLButtonElement>) => {
    form.resetFields();
    if (onCancel) {
      onCancel(event);
    }
  };
  return (
    <Modal
      okText="Save Password"
      okButtonProps={{
        size: "large",
      }}
      cancelButtonProps={{
        size: "large",
      }}
      onOk={form.submit}
      onCancel={handleOnCancel}
      {...props}
    >
      <Row justify="center" className="pt-4">
        <Col span={20}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
            requiredMark={false}
          >
            <Form.Item
              label="Current Password"
              name="currentPassword"
              rules={[
                { required: true, message: "Current password is required." },
              ]}
            >
              <Input.Password size="large" placeholder="••••••••••" />
            </Form.Item>

            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[{ required: true, message: "Password is required." }]}
            >
              <Input.Password size="large" placeholder="••••••••••" />
            </Form.Item>
            <Form.Item
              label="Confirm New Password"
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: "Confirm password is required.",
                },
              ]}
            >
              <Input.Password size="large" placeholder="•••••••" />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default ChangePasswordModal;

import ReportState from "./type";
import { createSlice } from "@reduxjs/toolkit";
import { all, call, put, StrictEffect, takeLatest } from "redux-saga/effects";
import { IGetReportsRequestData, IReport } from "../../types/Report";
import ReportAPI from "../../api/ReportAPI";
import { captureSentryException } from "../../config/Sentry";

const initialState: ReportState = {
  reports: [],
  fetchReportsLoading: false,
  fetchReportsSuccess: false,
  fetchReportsError: false,
};

export const reportSlice = createSlice({
  initialState,
  name: "reportSlice",
  reducers: {
    fetchReportsAction: (state, { payload }) => {
      state.reports = [];
      state.fetchReportsLoading = true;
      state.fetchReportsSuccess = false;
      state.fetchReportsError = false;
    },
    fetchReportsSuccessAction: (state, { payload }) => {
      state.fetchReportsLoading = false;
      state.fetchReportsSuccess = true;
      state.reports = payload;
    },
    fetchReportsErrorAction: (state) => {
      state.fetchReportsLoading = false;
      state.fetchReportsError = true;
    },
  },
});

function* fetchReportsSaga(action: {
  payload: IGetReportsRequestData;
}): Generator<StrictEffect, void, Array<IReport>> {
  try {
    const report = yield call(ReportAPI.fetchReports, action.payload);
    yield put(reportSlice.actions.fetchReportsSuccessAction(report));
  } catch (error) {
    yield put(reportSlice.actions.fetchReportsErrorAction());
    captureSentryException("SagaError", error as Error, {
      Saga: "FetchReportsSaga",
    });
  }
}

export function* watcherSaga() {
  yield all([
    takeLatest(reportSlice.actions.fetchReportsAction, fetchReportsSaga),
  ]);
}

import { ICreateTagRequestData, ITag } from "../types/Tag";
import API from "./API";

const api = new API();

export const createTag = async (
  tagData: ICreateTagRequestData,
): Promise<ITag> => {
  return api
    .callApi({
      url: "/tags",
      method: "POST",
      data: tagData,
    })
    .then(({ data }) => data.data);
};

export const fetchTags = async (): Promise<Array<ITag>> => {
  return api
    .callApi({
      url: "/tags",
      method: "GET",
    })
    .then(({ data }) => data.data);
};

const TagsAPI = {
  createTag,
  fetchTags,
};

export default TagsAPI;

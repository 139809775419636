import React from "react";
import { Select, SelectProps } from "antd";

const typeOptions = [
  {
    label: "Scene",
    value: "SCENE",
  },
  {
    label: "Other",
    value: "OTHER",
  },
];

const TagTypeSelector = (props: SelectProps) => {
  return (
    <Select
      size="large"
      placeholder="Select tag type..."
      options={typeOptions}
      {...props}
    />
  );
};

export default TagTypeSelector;

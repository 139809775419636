import React, { useEffect, useMemo, useState } from "react";
import { Col, Pagination, Row, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import IconEdit from "../../assets/icons/IconEdit";
import PageContainer from "../../components/PageContainer";
import { tagSlice } from "../../ducks/tagDuck/tagDuck";
import IReactState from "../../types/ReactState";
import SkeletonTable, {
  SkeletonTableColumnsType,
} from "../../components/SkeletonTable";
import DateUtils from "../../utils/DateUtils";

const Tags = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    pageSize: 50,
    currentPage: 1,
  });

  useEffect(() => {
    dispatch(tagSlice.actions.fetchTagsAction({ forceRefetch: true }));
  }, [dispatch]);

  const tagState = useSelector((state: IReactState) => state.tagState);

  const { tags, fetchTagsLoading } = tagState;

  const total = tags.length;
  const { currentPage, pageSize } = pagination;
  const start = pageSize * (currentPage - 1);
  let end = pageSize * (currentPage - 1) + pageSize;
  end = Math.min(end, total);

  const tagsSorted = useMemo(() => {
    return [...tags]
      .sort((a, b) => a.name.localeCompare(b.name))
      .slice(start, end);
  }, [tags, start, end]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Posts",
      dataIndex: "postsCount",
      key: "postsCount",
    },
    {
      title: "Images",
      dataIndex: "imagesCount",
      key: "imagesCount",
    },
    {
      title: "Starlets",
      dataIndex: "starletsCount",
      key: "starletsCount",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      render: () => (
        <Row>
          <Col>
            <Link to="/">
              <div className="p-2 h-4">
                <IconEdit />
              </div>
            </Link>
          </Col>
        </Row>
      ),
    },
  ];
  const dataSource = tagsSorted.map((tag) => {
    const {
      _id: tagId,
      name,
      type,
      postsCount,
      imagesCount,
      starletsCount,
      createdBy,
      createdAt,
    } = tag;
    return {
      key: tagId,
      name,
      type,
      postsCount,
      imagesCount,
      starletsCount,
      createdBy: createdBy.username,
      createdAt: DateUtils.getFormattedDate(createdAt),
    };
  });

  const onClickCreateTag = () => {
    navigate("/tags/create");
  };

  const actions = [
    {
      name: "Create Tag",
      onClick: onClickCreateTag,
    },
  ];

  return (
    <PageContainer title="Tags" actions={actions}>
      <div>
        <div>
          <SkeletonTable
            active={fetchTagsLoading}
            loading={fetchTagsLoading}
            columns={columns as SkeletonTableColumnsType[]}
          >
            <Table
              size="small"
              columns={columns}
              dataSource={dataSource}
              pagination={false}
            />
          </SkeletonTable>
        </div>
        <div>
          <div className="flex justify-between px-4 pt-4">
            <div>
              <span className="text-white">
                Showing {start}-{end} of {total}
              </span>
            </div>
            <div>
              <Pagination
                defaultCurrent={1}
                current={pagination.currentPage}
                total={total}
                pageSize={pagination.pageSize}
                onChange={(page, pageSize) => {
                  setPagination({
                    pageSize,
                    currentPage: page,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default Tags;

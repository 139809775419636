import { Col, Row, Button, Form, Input, Select } from "antd";
import React from "react";
import { GetPostsData } from "../types/Post";
import CategorySelector from "./selectors/CategorySelector";
import StarletsSelector from "./selectors/StarletsSelector";
import StudioSelector from "./selectors/StudioSelector";
import TagsSelector from "./selectors/TagsSelector";

interface IProps {
  setFilters: (filters: GetPostsData) => void;
  setColumns: (columns: Array<string>) => void;
}

const columnOptions = [
  {
    label: "Studio",
    value: "studio",
    default: true,
  },
  {
    label: "Starlets",
    value: "starlets",
    default: true,
  },
  {
    label: "Title",
    value: "title",
    default: true,
  },
  {
    label: "Images",
    value: "imagesCount",
    default: true,
  },
  {
    label: "Zip Size",
    value: "zipSize",
  },
  {
    label: "Released On",
    value: "releaseDate",
    default: true,
  },
  {
    label: "Downloads",
    value: "downloads",
    default: true,
  },
  {
    label: "Status",
    value: "status",
    default: true,
  },
  {
    label: "Cover",
    value: "cover",
  },
  {
    label: "Remark",
    value: "remark",
  },
  {
    label: "Created By",
    value: "createdBy",
  },
  {
    label: "Created On",
    value: "createdAt",
  },
];

const sortByOptions = [
  {
    label: "Created On",
    value: "createdAt",
  },
  {
    label: "Release Date",
    value: "releasedAt",
  },
  {
    label: "Updated On",
    value: "updatedAt",
  },
];

const sortOrderOptions = [
  {
    label: "Descending",
    value: "desc",
  },
  {
    label: "Ascending",
    value: "asc",
  },
];

const PostsFilters = (props: IProps) => {
  const [form] = Form.useForm();
  const { setFilters, setColumns } = props;

  const onChangeColumns = (values: Array<string>) => {
    setColumns(values);
  };
  return (
    <Form
      className="px-2 pt-2"
      form={form}
      layout="vertical"
      onFinish={setFilters}
      autoComplete="off"
      requiredMark={false}
      initialValues={{
        sortBy: {
          field: sortByOptions[0].value,
          order: sortOrderOptions[0].value,
        },
      }}
    >
      <Form.Item
        label="Search Title"
        name="title"
        rules={[
          {
            validateTrigger: "submit",
            validator: (rule, value) => {
              if (value && value.length < 3) {
                return Promise.reject(
                  new Error("At least 3 characters are required."),
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input size="large" placeholder="Search title" />
      </Form.Item>
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item label="Studio" name="studio">
            <StudioSelector />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Collection Category" name="collectionName">
            <CategorySelector allowClear />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="Starlets" name="starlets">
        <StarletsSelector />
      </Form.Item>
      <Form.Item label="Tags" name="tags">
        <TagsSelector type="ALL" />
      </Form.Item>
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item label="Sort By" name={["sortBy", "field"]}>
            <Select
              size="large"
              placeholder="Created On"
              options={sortByOptions}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Sort Order" name={["sortBy", "order"]}>
            <Select
              size="large"
              placeholder="Descending"
              options={sortOrderOptions}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="Columns">
        <Select
          allowClear
          size="large"
          mode="multiple"
          placeholder="Select columns to show in table..."
          maxTagCount="responsive"
          defaultValue={columnOptions
            .filter((options) => options.default)
            .map((options) => options.value)}
          options={columnOptions}
          onChange={onChangeColumns}
        />
      </Form.Item>
      <Row gutter={10} justify="center">
        <Col span={10}>
          <Form.Item>
            <Button
              block
              size="large"
              onClick={() => {
                setFilters({
                  currentPage: 1,
                  pageSize: 50,
                  sortBy: {
                    field: sortByOptions[1].value,
                    order: sortOrderOptions[1].value,
                  },
                });
                setColumns(
                  columnOptions
                    .filter((options) => options.default)
                    .map(({ value }) => value),
                );
                form.resetFields();
              }}
            >
              Reset
            </Button>
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item>
            <Button
              block
              size="large"
              type="primary"
              htmlType="submit"
              loading={false}
            >
              Search
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default PostsFilters;

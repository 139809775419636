import React, { useEffect } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import { userSlice } from "../../ducks/userDuck/userDuck";
import IReactState from "../../types/ReactState";
import SkeletonTable, {
  SkeletonTableColumnsType,
} from "../../components/SkeletonTable";
import { IUser } from "../../types/User";
import DateUtils from "../../utils/DateUtils";

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(userSlice.actions.fetchUsesAction());
  }, [dispatch]);

  const userState = useSelector((state: IReactState) => state.userState);
  const { users, fetchUsersLoading } = userState;

  const columns = [
    {
      title: "Username",
      key: "username",
      render: (user: IUser) => {
        return (
          <div className="flex items-center space-x-3">
            <div>
              <img
                className="w-8 rounded-full"
                src={user.avatar}
                alt="avatar"
              />
            </div>
            <div>{user.username}</div>
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "User Role",
      dataIndex: "userRole",
      key: "userRole",
    },
    {
      title: "Posts",
      dataIndex: "postsCount",
      key: "posts",
    },
    {
      title: "Images",
      dataIndex: "imagesCount",
      key: "images",
    },
    {
      title: "Starlets",
      dataIndex: "starletsCount",
      key: "starletsCount",
    },
    {
      title: "Tags",
      dataIndex: "tagsCount",
      key: "tagsCount",
    },
    {
      title: "Studios",
      dataIndex: "studiosCount",
      key: "studiosCount",
    },
    {
      title: "Joined On",
      dataIndex: "createdAt",
      key: "createdAt",
    },
  ];

  const dataSource = users.map((user) => {
    const {
      _id: userId,
      username,
      email,
      userRole,
      postsCount,
      imagesCount,
      starletsCount,
      tagsCount,
      studiosCount,
      avatar,
      createdAt,
    } = user;
    return {
      key: userId,
      username,
      email,
      userRole,
      postsCount,
      imagesCount,
      starletsCount,
      tagsCount,
      studiosCount,
      avatar,
      createdAt: DateUtils.getFormattedDate(createdAt),
    };
  });

  const onClickCreateUser = () => {
    navigate("/users/create");
  };

  const actions = [
    {
      name: "Create User",
      onClick: onClickCreateUser,
    },
  ];

  return (
    <PageContainer title="Users" actions={actions}>
      <div>
        <SkeletonTable
          active={fetchUsersLoading}
          loading={fetchUsersLoading}
          columns={columns as SkeletonTableColumnsType[]}
        >
          <Table
            size="small"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
          />
        </SkeletonTable>
      </div>
    </PageContainer>
  );
};

export default Users;

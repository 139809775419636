import { Col, Row, Switch } from "antd";
import React from "react";

interface IProps {
  setReset: (value: boolean) => void;
}

const ResetSwitch = (props: IProps) => {
  const { setReset } = props;
  return (
    <Row className="mb-6" justify="end" align="middle">
      <Col>
        <span className="px-2">Reset values after create?</span>
      </Col>
      <Col>
        <Switch
          defaultChecked
          checkedChildren="Yes"
          unCheckedChildren="No"
          onChange={setReset}
        />
      </Col>
    </Row>
  );
};

export default ResetSwitch;

import { IGetReportsRequestData, IReport } from "../types/Report";
import API from "./API";

const api = new API();

export const fetchReports = async (
  data: IGetReportsRequestData,
): Promise<Array<IReport>> => {
  return api
    .callApi({
      url: "/reports",
      method: "GET",
      params: data,
    })
    .then(({ data }) => data.data);
};

const ReportAPI = {
  fetchReports,
};

export default ReportAPI;

import React from "react";
import { Row, Col, Form, Button, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "../../components/PageContainer";
import UserRoleSelector from "../../components/selectors/UserRoleSelector";
import { userSlice } from "../../ducks/userDuck/userDuck";
import IReactState from "../../types/ReactState";
import { CreateUserData } from "../../types/User";

const UsersCreate = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const userState = useSelector((state: IReactState) => state.userState);
  const { createUserLoading } = userState;

  const onFinish = (userData: CreateUserData) => {
    createUser(userData);
  };

  const createUser = (userData: CreateUserData) => {
    dispatch(userSlice.actions.createUserAction(userData));
  };

  return (
    <PageContainer title="Create User">
      <Row justify="center">
        <Col span={20}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
            requiredMark={false}
          >
            <Form.Item
              label="User Role"
              name="userRole"
              rules={[{ required: true, message: "User role is required." }]}
            >
              <UserRoleSelector
                onChange={(userRole) => {
                  form.setFieldValue("userRole", userRole);
                }}
              />
            </Form.Item>

            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[{ required: true, message: "Username is required." }]}
                >
                  <Input size="large" placeholder="Username" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: "Email is required." }]}
                >
                  <Input size="large" placeholder="abc@example.com" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: "Password is required." }]}
                >
                  <Input.Password size="large" placeholder="•••••••" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Confirm Password"
                  name="confirmPassword"
                  rules={[
                    {
                      required: true,
                      message: "Confirm password is required.",
                    },
                  ]}
                >
                  <Input.Password size="large" placeholder="•••••••" />
                </Form.Item>
              </Col>
            </Row>

            <Row justify="center">
              <Col span={8}>
                <Form.Item>
                  <Button
                    block
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={createUserLoading}
                  >
                    {createUserLoading ? "Creating User" : "Create User"}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default UsersCreate;

import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Tag,
  theme,
  Typography,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ImagesAPI from "../api/ImagesAPI";
import { IStarlet } from "../types/Starlet";
import DateUtils from "../utils/DateUtils";
import Utils from "../utils/Utils";
import ValidationUtils from "../utils/ValidationUtils";
import ResetSwitch from "./ResetSwitch";
import TagsSelector from "./selectors/TagsSelector";

const { Dragger } = Upload;

interface IProps {
  isEdit: boolean;
  loading: boolean;
  starlet?: IStarlet;
  isSuccess: boolean;
  onSubmit: (postData: any) => void;
}

const StarletFrom = (props: IProps) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const {
    token: { colorBgContainer, colorPrimary },
  } = theme.useToken();
  const [resetOnSuccess, toggleReset] = useState(true);
  const [isPreviewVisible, togglePreview] = useState(false);
  const { starlet, isEdit, loading, isSuccess, onSubmit } = props;
  const initialValues = starlet ? Utils.starletFormInitialValues(starlet) : {};

  useEffect(() => {
    if (resetOnSuccess && isSuccess) {
      form.resetFields();
    }
  }, [form, resetOnSuccess, isSuccess]);

  const onFinish = (formData: any) => {
    onSubmit(formData);
  };
  const uploadImage = (key: string) => async (options: any) => {
    const { onSuccess, onError, file } = options;
    const url = await ImagesAPI.uploadImage({
      image: file,
    });
    if (!url) {
      return onError("Failed to upload image.");
    }
    form.setFieldValue(key, url);
    onSuccess("OK");
  };
  const onCancel = () => {
    navigate(-1);
  };

  const name = Form.useWatch("name", form);
  const cover = Form.useWatch("cover", form);
  const aka = Form.useWatch("aka", form);
  const dob = Form.useWatch("dob", form);
  const nativeName = Form.useWatch("nativeName", form);
  const tags = Form.useWatch("tags", form);

  return (
    <Row justify="center">
      <Col span={24}>
        <div className={!isPreviewVisible ? "hidden" : "block"}>
          <div className="pb-8">
            <div
              style={{
                background: colorBgContainer,
              }}
              className="px-8 pb-8 rounded-lg"
            >
              <div className="pb-4 pt-1">
                <Typography.Title level={4}>Starlet Preview</Typography.Title>
              </div>
              <Row>
                <Col>
                  <img src={cover} alt="" />
                </Col>
                <Col className="text-center">
                  <Typography.Title>
                    {name || "Dummy Starlet Name"} {nativeName}
                  </Typography.Title>
                  <Typography.Title>{aka || "NA"}</Typography.Title>
                  <Typography.Title>{dob || "NA"}</Typography.Title>
                  <Typography.Title>
                    {name || "Dummy Starlet Name"}
                  </Typography.Title>
                  <Space size="small">
                    {tags?.map(({ name }: { name: string }, index: number) => (
                      <Tag color={colorPrimary} key={index}>
                        {name}
                      </Tag>
                    ))}
                  </Space>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Col>
      <Col span={20}>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          initialValues={initialValues}
          onFinish={onFinish}
          requiredMark={false}
        >
          <Row gutter={10} justify="center">
            <Col md={8}>
              <Form.Item hidden name="avatar">
                <Input type="hidden" />
              </Form.Item>
              <Form.Item>
                <Dragger
                  accept="image/jpeg"
                  customRequest={uploadImage("avatar")}
                  onRemove={() => form.resetFields(["avatar"])}
                >
                  <div className="px-4">
                    <p className="ant-upload-drag-icon"></p>
                    <p className="ant-upload-text">Starlet Avatar Image</p>
                    <p className="ant-upload-hint">
                      Image must be a square and at least 100x100 resolution
                    </p>
                    <p className="text-sm text-zinc-800">
                      Click or drag image file to this area to upload
                    </p>
                  </div>
                </Dragger>
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item hidden name="cover">
                <Input type="hidden" />
              </Form.Item>
              <Form.Item>
                <Dragger
                  accept="image/jpeg"
                  customRequest={uploadImage("cover")}
                >
                  <div className="px-4">
                    <p className="ant-upload-drag-icon"></p>
                    <p className="ant-upload-text">Starlet Cover Image</p>
                    <p className="ant-upload-hint">
                      Image must have 3:4 aspect ratio and at least 800x1080
                      resolution
                    </p>
                    <p className="text-sm text-zinc-800">
                      Click or drag image file to this area to upload
                    </p>
                  </div>
                </Dragger>
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item hidden name="backgroundCover">
                <Input type="hidden" />
              </Form.Item>
              <Form.Item>
                <Dragger
                  accept="image/jpeg"
                  customRequest={uploadImage("backgroundCover")}
                >
                  <div className="px-4">
                    <p className="ant-upload-drag-icon"></p>
                    <p className="ant-upload-text">
                      Starlet Background Cover Image
                    </p>
                    <p className="ant-upload-hint">
                      Image must have 16:9 aspect ratio and at least 1080x1080
                      resolution
                    </p>
                    <p className="text-sm text-zinc-800">
                      Click or drag image file to this area to upload
                    </p>
                  </div>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label="Starlet Name"
                name="name"
                rules={[
                  { required: true, message: "Starlet name is required." },
                  {
                    validateTrigger: "submit",
                    validator: (rule, value) => {
                      if (value && value.length < 3) {
                        return Promise.reject(
                          "Short starlet name, at least 3 characters are required.",
                        );
                      }
                      if (!value || ValidationUtils.isValidName(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Name must only contain alpha-numeric characters.",
                      );
                    },
                  },
                ]}
              >
                <Input size="large" placeholder="Starlet name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Name in native language" name="nativeName">
                <Input
                  size="large"
                  placeholder="Starlet's name in native language"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item label="Also Known As" name="aka">
                <Input size="large" placeholder="Starlet's other names" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Date of Birth"
                name="dob"
                rules={[
                  {
                    validateTrigger: "submit",
                    validator: (rule, value) => {
                      if (!value || DateUtils.isValid(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        `Invalid date of birth, should be in ${DateUtils.getCurrentDate()} format.`,
                      );
                    },
                  },
                ]}
              >
                <Input size="large" placeholder={DateUtils.getCurrentDate()} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Tags"
            name="tags"
            rules={[{ required: true, message: "Select tags." }]}
          >
            <TagsSelector
              onChange={(tags) => {
                form.setFieldValue("tags", tags);
              }}
              type="OTHER"
            />
          </Form.Item>
          {toggleReset && <ResetSwitch setReset={toggleReset} />}

          <Row gutter={10} justify="center">
            {isEdit && (
              <Col span={8}>
                <Form.Item>
                  <Button block size="large" onClick={onCancel}>
                    Cancel
                  </Button>
                </Form.Item>
              </Col>
            )}
            <Col span={8}>
              <Form.Item>
                <Button
                  block
                  size="large"
                  onClick={() => togglePreview(!isPreviewVisible)}
                >
                  {isPreviewVisible ? "Hide Preview" : "Show Preview"}
                </Button>
              </Form.Item>
            </Col>
            {!isEdit && (
              <Col span={8}>
                <Form.Item>
                  <Button
                    block
                    size="large"
                    disabled={loading}
                    onClick={() => form.resetFields()}
                  >
                    Reset
                  </Button>
                </Form.Item>
              </Col>
            )}
            <Col span={8}>
              <Form.Item>
                <Button
                  block
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {!isEdit && (
                    <>{loading ? "Creating Starlet" : "Create Starlet"}</>
                  )}
                  {isEdit && (
                    <>{loading ? "Updating Starlet" : "Update Starlet"}</>
                  )}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default StarletFrom;

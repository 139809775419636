import API from "./API";

//https://api.imgbb.com/1/upload
//sifaxe@finews.biz
//sifaxexx
//Kj!a0990hzFfrPsBA%cQ2Lz
//https://thumbsnap.com/

const API_ENDPOINT = "https://api.imgbb.com/1/upload";
const API_KEY = "9716aa27fd8f94c97de4206c462ed292";

const api = new API();

interface UploadRequestData {
  image: File;
}

const uploadImage = async (
  uploadData: UploadRequestData,
): Promise<string | null> => {
  const { image } = uploadData;
  const formData = new FormData();
  formData.set("image", image);
  formData.set("host", "IMGBB");
  formData.set("uploadType", "FILE");
  return api
    .callApi({
      url: "/images",
      method: "POST",
      data: formData,
      headers: {
        "Content-type": "multipart/form-data",
      },
    })
    .then(({ data }) => data.data.url);
};

const ImagesAPI = {
  uploadImage,
};

export default ImagesAPI;

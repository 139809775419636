import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Discussion from "../pages/discussion/Discussion";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Posts from "../pages/posts/Posts";
import PostsCreate from "../pages/posts/PostsCreate";
import Profile from "../pages/Profile";
import Reports from "../pages/Reports";
import Starlets from "../pages/starlets/Starlets";
import StarletsCreate from "../pages/starlets/StarletsCreate";
import Tags from "../pages/tags/Tags";
import TagsCreate from "../pages/tags/TagsCreate";
import Users from "../pages/users/Users";
import UsersCreate from "../pages/users/UsersCreate";
import Studios from "../pages/studios/Studios";
import StudiosCreate from "../pages/studios/StudiosCreate";
import NotFound from "../pages/NotFound";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Notes from "../pages/Notes";
import PostsView from "../pages/posts/PostsView";
import StarletsEdit from "../pages/starlets/StarletsEdit";
import PostsEdit from "../pages/posts/PostsEdit";

const privateRoutes = [
  {
    key: "home",
    path: "/",
    component: <Home />,
  },
  {
    key: "dashboard",
    path: "dashboard",
    component: <Dashboard />,
  },
  {
    key: "reports",
    path: "reports",
    component: <Reports />,
  },
  {
    key: "posts",
    path: "posts",
    component: <Posts />,
  },
  {
    key: "posts-create",
    path: "posts/create",
    component: <PostsCreate />,
  },
  {
    key: "posts-view",
    path: "posts/view/:postId",
    component: <PostsView />,
  },
  {
    key: "posts-edit",
    path: "posts/edit/:postId",
    component: <PostsEdit />,
  },
  {
    key: "starlets",
    path: "starlets",
    component: <Starlets />,
  },
  {
    key: "starlets-create",
    path: "/starlets/create",
    component: <StarletsCreate />,
  },
  {
    key: "starlets-edit",
    path: "/starlets/edit/:starletId",
    component: <StarletsEdit />,
  },
  {
    key: "tags",
    path: "/tags",
    component: <Tags />,
  },
  {
    key: "tags-create",
    path: "/tags/create",
    component: <TagsCreate />,
  },
  {
    key: "studios",
    path: "/studios",
    component: <Studios />,
  },
  {
    key: "studios-create",
    path: "/studios/create",
    component: <StudiosCreate />,
  },
  {
    key: "users",
    path: "/users",
    component: <Users />,
  },
  {
    key: "users-create",
    path: "/users/create",
    component: <UsersCreate />,
  },
  {
    key: "profile",
    path: "/profile",
    component: <Profile />,
  },
  {
    key: "notes",
    path: "/notes",
    component: <Notes />,
  },
  {
    key: "discussion",
    path: "/discussion",
    component: <Discussion />,
  },
];

const publicRoutes = [
  {
    key: "login",
    path: "/login",
    component: <Login />,
  },
];

const AppRoutes = () => {
  return (
    <Routes>
      {privateRoutes.map(({ key, path, component }) => (
        <Route
          key={key}
          path={path}
          element={<PrivateRoute>{component}</PrivateRoute>}
        />
      ))}
      {publicRoutes.map(({ key, path, component }) => (
        <Route
          key={key}
          path={path}
          element={<PublicRoute>{component}</PublicRoute>}
        />
      ))}
      <Route
        path="*"
        element={
          <PrivateRoute>
            <NotFound />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;

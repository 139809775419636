import { Button, Col, Form, Input, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "../../components/PageContainer";
import ResetSwitch from "../../components/ResetSwitch";
import TagTypeSelector from "../../components/selectors/TagTypeSelector";
import { tagSlice } from "../../ducks/tagDuck/tagDuck";
import IReactState from "../../types/ReactState";
import { CreateTagData } from "../../types/Tag";
import ValidationUtils from "../../utils/ValidationUtils";

const TagsCreate = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [resetOnSuccess, setResetOnSuccess] = useState(true);
  const tagState = useSelector((state: IReactState) => state.tagState);

  const {
    createTagLoading,
    createTagSuccess,
    createTagError,
    createTagErrorMessage,
  } = tagState;

  useEffect(() => {
    if (createTagSuccess) {
      notification.success({
        message: "Tag Created",
        description: `${form.getFieldValue("name")} successfully created.`,
      });
      if (resetOnSuccess) {
        form.resetFields();
      }
    }
    if (createTagError) {
      notification.error({
        message: "Create Tag Failed",
        description:
          createTagErrorMessage ||
          "Something went wrong, failed to create tag.",
      });
    }
    return () => {
      dispatch(tagSlice.actions.createTagResetAction());
    };
  }, [
    dispatch,
    form,
    createTagSuccess,
    createTagError,
    createTagErrorMessage,
    resetOnSuccess,
  ]);

  const onFinish = (tagData: CreateTagData) => {
    createTag(tagData);
  };

  const createTag = (tagData: CreateTagData) => {
    dispatch(tagSlice.actions.createTagAction(tagData));
  };

  return (
    <PageContainer title="Create Tag">
      <div>
        <Row justify="center">
          <Col span={20}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
              requiredMark={false}
            >
              <Form.Item
                label="Tag Name"
                name="name"
                rules={[
                  { required: true, message: "Tag name is required." },
                  {
                    validateTrigger: "submit",
                    validator: (rule, value) => {
                      if (value && value.length < 3) {
                        return Promise.reject(
                          "Short tag name, at least 3 characters are required.",
                        );
                      }
                      if (!value || ValidationUtils.isValidName(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Name must only contain alpha-numeric characters.",
                      );
                    },
                  },
                ]}
              >
                <Input size="large" placeholder="Tag name" />
              </Form.Item>
              <Form.Item
                label="Tag Type"
                name="type"
                rules={[{ required: true, message: "Select tag type." }]}
              >
                <TagTypeSelector
                  onChange={(type) => {
                    form.setFieldValue("type", type);
                  }}
                />
              </Form.Item>
              <ResetSwitch setReset={setResetOnSuccess} />

              <Row gutter={10} justify="center">
                <Col span={8}>
                  <Form.Item>
                    <Button
                      block
                      size="large"
                      disabled={createTagLoading}
                      onClick={() => form.resetFields()}
                    >
                      Reset
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item>
                    <Button
                      block
                      size="large"
                      type="primary"
                      htmlType="submit"
                      disabled={createTagLoading}
                      loading={createTagLoading}
                    >
                      {createTagLoading ? "Creating Tag" : "Create Tag"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </PageContainer>
  );
};

export default TagsCreate;

import { createSlice } from "@reduxjs/toolkit";
import {
  all,
  call,
  put,
  select,
  StrictEffect,
  takeLatest,
} from "redux-saga/effects";
import TagsAPI from "../../api/TagsAPI";
import IReactState from "../../types/ReactState";
import { CreateTagData, ICreateTagRequestData, ITag } from "../../types/Tag";
import TagState from "./types";
import { AxiosError } from "axios";
import { captureSentryException } from "../../config/Sentry";

const initialState: TagState = {
  createTagLoading: false,
  createTagSuccess: false,
  createTagError: false,
  createTagErrorMessage: "",

  tags: [],
  fetchTagsLoading: false,
  fetchTagsSuccess: false,
  fetchTagsError: false,
  fetchTagsState: "NONE",
};
export const tagSlice = createSlice({
  initialState,
  name: "tagSlice",
  reducers: {
    createTagAction: (state, { payload }) => {
      state.createTagLoading = true;
      state.createTagSuccess = false;
      state.createTagError = false;
      state.createTagErrorMessage = "";
    },
    createTagSuccessAction: (state, { payload }) => {
      state.createTagLoading = false;
      state.createTagSuccess = true;
      state.tags = [...state.tags, payload];
    },
    createTagErrorAction: (state, { payload }) => {
      state.createTagLoading = false;
      state.createTagError = true;
      state.createTagErrorMessage = payload;
    },
    createTagResetAction: (state) => {
      state.createTagLoading = false;
      state.createTagSuccess = false;
      state.createTagError = false;
      state.createTagErrorMessage = "";
    },
    fetchTagsAction: (state, { payload }) => {
      state.fetchTagsLoading = true;
    },
    fetchTagsSuccessAction: (state, { payload }) => {
      state.fetchTagsLoading = false;
      state.fetchTagsSuccess = true;
      state.tags = payload;
      state.fetchTagsState = "FRESH";
    },
    fetchTagsErrorAction: (state) => {
      state.tags = [];
      state.fetchTagsLoading = false;
      state.fetchTagsSuccess = false;
      state.fetchTagsError = true;
    },
    fetchTagsSetState: (state, { payload }) => {
      state.fetchTagsState = payload;
    },
  },
});

function* createTagSaga(action: {
  payload: CreateTagData;
}): Generator<StrictEffect, void, ITag> {
  const { name, type } = action.payload;
  const tagData: ICreateTagRequestData = {
    name,
    type,
  };
  try {
    const tag = yield call(TagsAPI.createTag, tagData);
    yield put(tagSlice.actions.createTagSuccessAction(tag));
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response?.status === 409) {
      yield put(
        tagSlice.actions.createTagErrorAction(
          "Failed to create tag because it already exists.",
        ),
      );
      return;
    }
    yield put(tagSlice.actions.createTagErrorAction(""));
    captureSentryException("SagaError", error as Error, {
      Saga: "CreateTagSaga",
    });
  }
}

function* fetchTagsSaga(): Generator<StrictEffect, void, string | Array<ITag>> {
  try {
    const state = yield select(
      (state: IReactState) => state.tagState.fetchTagsState,
    );
    if (state === "FRESH") {
      const tags = yield select((state: IReactState) => state.tagState.tags);
      yield put(tagSlice.actions.fetchTagsSuccessAction(tags));
      return;
    }
    let tags = yield call(TagsAPI.fetchTags);
    tags = (tags as Array<ITag>).filter(({ type }) =>
      ["OTHER", "SCENE"].includes(type),
    );
    yield put(tagSlice.actions.fetchTagsSuccessAction(tags));
  } catch (error) {
    yield put(tagSlice.actions.fetchTagsErrorAction());
    captureSentryException("SagaError", error as Error, {
      Saga: "FetchTagsSaga",
    });
  }
}

export function* watcherSaga() {
  yield all([
    takeLatest(tagSlice.actions.createTagAction, createTagSaga),
    takeLatest(tagSlice.actions.fetchTagsAction, fetchTagsSaga),
  ]);
}

import { ICreateUserRequestData, IUser } from "../types/User";
import API from "./API";

const api = new API();

export const createUser = async (
  userData: ICreateUserRequestData,
): Promise<IUser> => {
  return api
    .callApi({
      url: "/users",
      method: "POST",
      data: userData,
    })
    .then(({ data }) => data.data);
};

export const fetchUserProfile = async (): Promise<IUser> => {
  return api
    .callApi({
      url: "/users/profile",
      method: "GET",
    })
    .then(({ data }) => data.data);
};

export const fetchUsers = async (): Promise<Array<IUser>> => {
  return api
    .callApi({
      url: "/users",
      method: "GET",
    })
    .then(({ data }) => data.data);
};

const UserAPI = {
  createUser,
  fetchUserProfile,
  fetchUsers,
};

export default UserAPI;

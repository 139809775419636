import { notification, Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "../../components/PageContainer";
import PostFrom from "../../components/PostForm";
import { postSlice } from "../../ducks/postDuck/postDuck";
import { CreatePostData, IDraftPost } from "../../types/Post";
import IReactState from "../../types/ReactState";
import DraftPosts from "../../components/DraftPosts";
import Firebase from "../../config/Firebase";
import { ref, push, remove } from "firebase/database";

const PostsCreate = () => {
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState(0);
  const [draftPost, setDraftPost] = useState<IDraftPost>();

  const postState = useSelector((state: IReactState) => state.postState);
  const {
    createdPost,
    createPostLoading,
    createPostSuccess,
    createPostError,
    createPostErrorMessage,
  } = postState;

  useEffect(() => {
    if (createPostSuccess) {
      notification.success({
        message: "Post Created",
        description: `${createdPost?.title} successfully created.`,
      });
      if (draftPost) {
        const firebase = new Firebase();
        const db = firebase.getDatabase();
        const archivedPhotosets = ref(db, "Photosets/Archived");
        push(archivedPhotosets, draftPost);
        const currentPost = ref(db, `Photosets/Active/${draftPost.postId}`);
        remove(currentPost);
      }
    }
    if (createPostError) {
      notification.error({
        message: "Create Post Failed",
        description:
          createPostErrorMessage ||
          "Something went wrong, failed to create post.",
      });
    }
    return () => {
      dispatch(postSlice.actions.createPostResetAction());
    };
  }, [
    draftPost,
    dispatch,
    createdPost,
    createPostSuccess,
    createPostError,
    createPostErrorMessage,
  ]);

  const createPost = (postData: CreatePostData) => {
    dispatch(postSlice.actions.createPostAction(postData));
  };

  const onClickToggleDraftPosts = () => {
    if (activeKey === 1) {
      setActiveKey(0);
    } else {
      setActiveKey(1);
    }
  };

  const actions = [
    {
      name: activeKey ? "Hide Draft Posts" : "Show Draft Posts",
      onClick: onClickToggleDraftPosts,
    },
  ];

  return (
    <PageContainer title="Create Post" actions={actions}>
      <>
        <Collapse bordered={false} activeKey={activeKey} collapsible="icon">
          <Collapse.Panel showArrow={false} header="" key="1">
            <DraftPosts setDraftPost={setDraftPost} />
          </Collapse.Panel>
        </Collapse>
        <PostFrom
          draftPost={draftPost}
          isEdit={false}
          loading={createPostLoading}
          isSuccess={createPostSuccess}
          onSubmit={createPost}
        />
      </>
    </PageContainer>
  );
};

export default PostsCreate;

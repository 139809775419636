import { notification, Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import PostFrom from "../../components/PostForm";
import { postSlice } from "../../ducks/postDuck/postDuck";
import { UpdatePostData } from "../../types/Post";
import IReactState from "../../types/ReactState";

const PostsEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const postState = useSelector((state: IReactState) => state.postState);

  const {
    post,
    fetchPostByIdLoading,
    updatePostByIdLoading,
    updatePostByIdSuccess,
    updatePostByIdError,
    updatePostByIdErrorMessage,
  } = postState;
  const { postId } = params;

  useEffect(() => {
    dispatch(postSlice.actions.fetchPostByIdAction(postId));
  }, [dispatch, postId]);

  useEffect(() => {
    if (updatePostByIdSuccess) {
      notification.success({
        message: "Post Updated",
        description: `${post?.title} successfully updated.`,
      });
    }
    if (updatePostByIdError) {
      notification.error({
        message: "Update Post Failed",
        description:
          updatePostByIdErrorMessage ||
          "Something went wrong, failed to update post.",
      });
    }
    return () => {
      dispatch(postSlice.actions.updatePostByIdResetAction());
    };
  }, [
    dispatch,
    post,
    updatePostByIdSuccess,
    updatePostByIdError,
    updatePostByIdErrorMessage,
  ]);

  const updatePost = (postData: UpdatePostData) => {
    dispatch(postSlice.actions.updatePostByIdAction({ ...postData, postId }));
  };

  return (
    <PageContainer title="Update Post">
      <>
        {fetchPostByIdLoading && (
          <div className="flex flex-col items-center justify-center h-full w-full ">
            <div className="text-center">
              <Spin size="large" />
              <h5 className="text-rose-700">Loading Please Wait...</h5>
            </div>
          </div>
        )}
        {post && (
          <PostFrom
            isEdit={true}
            post={post}
            isSuccess={updatePostByIdSuccess}
            loading={updatePostByIdLoading}
            onSubmit={updatePost}
          />
        )}
      </>
    </PageContainer>
  );
};

export default PostsEdit;

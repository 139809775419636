import React from "react";
import { Link, useLocation } from "react-router-dom";
import IconDashboard from "../assets/icons/IconDashboard";
import IconDiscussion from "../assets/icons/IconDiscussion";
import IconPosts from "../assets/icons/IconPost";
import IconReports from "../assets/icons/IconReport";
import IconStarlet from "../assets/icons/IconStarlet";
import IconTag from "../assets/icons/IconTag";
import IconUser from "../assets/icons/IconUser";
import IconUsers from "../assets/icons/IconUsers";
import IconStudio from "../assets/icons/IconStudio";
import { useDispatch, useSelector } from "react-redux";
import IReactState from "../types/ReactState";
import { appSlice } from "../ducks/appDuck/appDuck";
import IconNotes from "../assets/icons/IconNotes";

const navItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
    icon: <IconDashboard />,
  },
  {
    title: "Reports",
    link: "/reports",
    icon: <IconReports />,
  },
  {
    title: "Posts",
    link: "/posts",
    icon: <IconPosts />,
  },
  {
    title: "Starlets",
    link: "/starlets",
    icon: <IconStarlet />,
  },
  {
    title: "Tags",
    link: "/tags",
    icon: <IconTag />,
  },
  {
    title: "Studios",
    link: "/studios",
    icon: <IconStudio />,
  },
  {
    title: "Users",
    link: "/users",
    icon: <IconUsers />,
  },
  {
    title: "Notes",
    link: "/notes",
    icon: <IconNotes />,
  },
  {
    title: "Discussion",
    link: "/discussion",
    icon: <IconDiscussion />,
  },
];
interface INavItemProps {
  title: string;
  link: string;
  icon: JSX.Element;
  active?: boolean;
  collapsed: boolean;
}

const NavItem = (props: INavItemProps) => {
  const { title, link, icon, collapsed, active } = props;
  let className =
    "flex items-center py-2 px-6 text-white text-xl font-medium transition-all duration-200 ease-out";
  if (active) {
    className = `${className} text-rose-600`;
  } else {
    className = `${className} hover:text-rose-600`;
  }
  if (collapsed) {
    className = `h-8 ${className}`;
  } else {
    className = `h-6 ${className}`;
  }

  return (
    <div>
      <Link to={link} className="no-underline">
        <span className={className}>
          <span className="h-full">{icon}</span>
          <span className={`pl-2 overflow-hidden ${collapsed && "w-0"}`}>
            {title}
          </span>
        </span>
      </Link>
    </div>
  );
};

const SideBar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const activeKey = location.pathname;
  const { collapsed } = useSelector((state: IReactState) => state.appState);
  const toggleSidebar = () => {
    dispatch(appSlice.actions.toggleSidebar());
  };
  let className =
    "fixed z-10 top-0 left-0 bottom-0 bg-black transition-all duration-500 ease-out whitespace-nowrap border-t-0 border-b-0 border-l-0 border-r-1 border-gray-900 border-solid";
  let brandNameClass = "inline-block transition-all duration-500 ease-out";
  if (collapsed) {
    className = `${className} w-20`;
    brandNameClass = `${brandNameClass} scale-0 origin-left`;
  } else {
    className = `${className} w-52`;
  }
  return (
    <aside className={className}>
      <div className="flex flex-col justify-between h-full">
        <div>
          <div
            className="border-t-0 border-b-1 border-l-0 border-r-0 border-gray-900 border-solid cursor-pointer"
            onClick={() => toggleSidebar()}
          >
            <h1 className="h-10 text-rose-600 text-center fw-extrabold">
              <span className="inline-block text-3xl">{collapsed && "RP"}</span>
              <span className={brandNameClass}>{!collapsed && "RedPics"}</span>
            </h1>
          </div>
          <div className="space-y-1 pt-10">
            {navItems.map(({ title, link, icon }) => (
              <NavItem
                key={title}
                title={title}
                link={link}
                icon={icon}
                collapsed={collapsed}
                active={activeKey.includes(link)}
              />
            ))}
          </div>
        </div>
        <div>
          <div>
            <div className="py-4 border-t-1 border-b-0 border-l-0 border-r-0 border-gray-900 border-solid">
              <NavItem
                title="Profile"
                link="/profile"
                icon={<IconUser />}
                collapsed={collapsed}
                active={activeKey.includes("/profile")}
              />
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default SideBar;

import React, { useEffect } from "react";
import { notification, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "../../components/PageContainer";
import { starletSlice } from "../../ducks/starletDuck/starletDuck";
import IReactState from "../../types/ReactState";
import { UpdateStarletData } from "../../types/Starlet";
import { useParams } from "react-router-dom";
import StarletFrom from "../../components/StarletForm";

const StarletsEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { starletId } = params;

  const starletState = useSelector((state: IReactState) => state.starletState);

  const {
    starlet,
    fetchStarletByIdLoading,
    fetchStarletByIdError,
    fetchStarletByIdErrorMessage,
    updateStarletByIdLoading,
    updateStarletByIdSuccess,
    updateStarletByIdError,
    updateStarletByIdErrorMessage,
  } = starletState;

  useEffect(() => {
    dispatch(starletSlice.actions.fetchStarletByIdAction(starletId));

    return () => {
      dispatch(starletSlice.actions.createStarletResetAction());
      dispatch(starletSlice.actions.updateStarletByIdResetAction());
    };
  }, [dispatch, starletId]);

  useEffect(() => {
    if (fetchStarletByIdError) {
      notification.error({
        message: "Updated Starlet Failed",
        description:
          fetchStarletByIdErrorMessage ||
          "Something went wrong, failed to updated starlet.",
      });
    }
  }, [fetchStarletByIdError, fetchStarletByIdErrorMessage]);

  useEffect(() => {
    if (updateStarletByIdSuccess) {
      notification.success({
        message: "Starlet Updated",
        description: `${starlet?.name} successfully updated.`,
      });
    }
    if (updateStarletByIdError) {
      notification.error({
        message: "Updated Starlet Failed",
        description:
          updateStarletByIdErrorMessage ||
          "Something went wrong, failed to updated starlet.",
      });
    }
  }, [
    starlet,
    updateStarletByIdSuccess,
    updateStarletByIdError,
    updateStarletByIdErrorMessage,
  ]);

  const updateStarlet = (starletData: UpdateStarletData) => {
    dispatch(
      starletSlice.actions.updateStarletByIdAction({
        ...starletData,
        starletId,
      }),
    );
  };

  return (
    <PageContainer title="Edit Starlet">
      <>
        {fetchStarletByIdLoading && (
          <div className="flex flex-col items-center justify-center h-full w-full ">
            <div className="text-center">
              <Spin size="large" />
              <h5 className="text-rose-700">Loading Please Wait...</h5>
            </div>
          </div>
        )}
        {starlet && (
          <StarletFrom
            isEdit={true}
            isSuccess={updateStarletByIdSuccess}
            starlet={starlet}
            loading={updateStarletByIdLoading}
            onSubmit={updateStarlet}
          />
        )}
      </>
    </PageContainer>
  );
};

export default StarletsEdit;

import React, { useEffect, useMemo, useState } from "react";
import { Col, Pagination, Row, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import IconEdit from "../../assets/icons/IconEdit";
import PageContainer from "../../components/PageContainer";
import { starletSlice } from "../../ducks/starletDuck/starletDuck";
import IReactState from "../../types/ReactState";
import SkeletonTable, {
  SkeletonTableColumnsType,
} from "../../components/SkeletonTable";
import DateUtils from "../../utils/DateUtils";

const Starlets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    pageSize: 50,
    currentPage: 1,
  });

  useEffect(() => {
    dispatch(starletSlice.actions.fetchStarletsAction());
  }, [dispatch]);

  const starletState = useSelector((state: IReactState) => state.starletState);

  const { starlets, fetchStarletsLoading } = starletState;

  const total = starlets.length;
  const { currentPage, pageSize } = pagination;
  const start = pageSize * (currentPage - 1);
  let end = pageSize * (currentPage - 1) + pageSize;
  end = Math.min(end, total);

  const starletsSorted = useMemo(() => {
    return [...starlets]
      .sort((a, b) => a.name.localeCompare(b.name))
      .slice(start, end);
  }, [starlets, start, end]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "AKA",
      dataIndex: "aka",
      key: "aka",
    },
    {
      title: <span className="whitespace-nowrap">Date of Birth</span>,
      dataIndex: "dob",
      key: "dob",
    },
    {
      title: "Posts",
      dataIndex: "posts",
      key: "posts",
    },
    {
      title: "Images",
      dataIndex: "images",
      key: "images",
    },
    {
      title: <span className="whitespace-nowrap">Created By</span>,
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: <span className="whitespace-nowrap">Created On</span>,
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Action",
      dataIndex: "starletId",
      key: "action",
      render: (starletId: string) => (
        <Row>
          <Col>
            <Link to={`/starlets/edit/${starletId}`}>
              <div className="p-2 h-4">
                <IconEdit />
              </div>
            </Link>
          </Col>
        </Row>
      ),
    },
  ];

  const dataSource = starletsSorted.map((starlet) => {
    const {
      _id: starletId,
      name,
      aka,
      nativeName,
      dob,
      imagesCount,
      postsCount,
      createdBy,
      createdAt,
    } = starlet;
    return {
      key: starletId,
      starletId,
      name,
      aka:
        nativeName || aka
          ? `${nativeName ? nativeName : ""} ${aka ? aka : ""}`
          : "NA",
      dob: dob || "NA",
      images: imagesCount,
      posts: postsCount,
      createdBy: createdBy.username,
      createdAt: DateUtils.getFormattedDate(createdAt),
    };
  });
  const onClickCreateStarlet = () => {
    navigate("/starlets/create");
  };

  const actions = [
    {
      name: "Create Starlet",
      onClick: onClickCreateStarlet,
    },
  ];

  return (
    <PageContainer title="Starlets" actions={actions}>
      <div>
        <div>
          <SkeletonTable
            active={fetchStarletsLoading}
            loading={fetchStarletsLoading}
            columns={columns as SkeletonTableColumnsType[]}
          >
            <Table
              size="small"
              columns={columns}
              dataSource={dataSource}
              pagination={false}
            />
          </SkeletonTable>
        </div>
        <div>
          <div className="flex justify-between px-4 pt-4">
            <div>
              <span className="text-white">
                Showing {start}-{end} of {total}
              </span>
            </div>
            <div>
              <Pagination
                defaultCurrent={1}
                current={pagination.currentPage}
                total={total}
                pageSize={pagination.pageSize}
                onChange={(page, pageSize) => {
                  setPagination({
                    pageSize,
                    currentPage: page,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default Starlets;

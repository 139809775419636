import { REMARKS } from "../consts/consts";
import { IPost } from "../types/Post";
import { IStarlet } from "../types/Starlet";
import { IStudio } from "../types/Studio";

export class Utils {
  public static shuffleArray = (array: Array<any>) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  };

  public static capitalize = (str: string) => {
    return str
      .split(" ")
      .map((str) => {
        if (str) {
          return str[0].toUpperCase() + str.slice(1);
        }
        return str;
      })
      .join(" ");
  };

  public static postToString = (post: IPost) => {
    const {
      studio,
      starlets,
      title,
      content,
      downloads,
      imagesCount,
      releaseDate,
      zipSize,
      imageHeight,
      imageWidth,
      remark,
    } = post;
    const starletsNames = [...starlets]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ name }) => name)
      .join(", ");
    const downloadString = downloads
      .filter(({ filehost }) =>
        ["K2S", "TEZFILES", "DROP-DOWNLOAD"].includes(filehost),
      )
      .map(({ filehost, url }) => `${filehost}: [url=${url}]${url}[/url]`)
      .join("\n");

    const titleData = [
      starletsNames,
      title.replace(/—/gi, "-"),
      `${imagesCount}x`,
      `${imageWidth}x${imageHeight}px`,
      remark === "HIGH_RESOLUTION" ? null : REMARKS[remark],
      releaseDate,
      Utils.formatFileSize(zipSize),
    ].filter((elm) => elm);
    return `[CENTER]
    [FONT=Palatino Linotype]
    [COLOR="#000000"][SIZE=6]${studio.name}[/SIZE][/COLOR]
    [SIZE=4][COLOR="#000000"]${titleData.join(" - ")}
    [/COLOR][SIZE=4]Download Zip[/SIZE]
    ${downloadString} 
    [/SIZE]
    [/FONT]
    ${Utils.htmlToBBCode(content)}
    [/CENTER]`;
  };

  public static postStudioSelect = (studio: IStudio) => {
    const { name, primaryContent, defaultValues } = studio;
    const imageWidth = defaultValues.imageWidth;
    const imageHeight = defaultValues.imageHeight;
    let coverImagesCount = 0;
    if (
      [
        "Watch4Beauty",
        "XiuRen",
        "BimilStory",
        "MiiTao",
        "XiaoYu",
        "YouMi",
        "MFStar",
      ].includes(name)
    ) {
      coverImagesCount = 1;
    }
    return {
      coverImagesCount,
      imageWidth,
      imageHeight,
      collectionName: primaryContent,
    };
  };

  public static postFormInitialValues = (post: IPost) => {
    const { zipSize } = post;
    return {
      studio: post.studio,
      title: post.title,
      description: post.description,
      starlets: post.starlets,
      collectionName: post.collectionName,
      imagesCount: post.imagesCount,
      content: post.content,
      cover: post.cover,
      imageHeight: post.imageHeight,
      imageWidth: post.imageWidth,
      releaseDate: post.releaseDate,
      zipSize: {
        fileSize: zipSize / (zipSize < 1024 ? 1 : 1024),
        sizeIn: zipSize < 1024 ? "MB" : "GB",
      },
      tags: post.tags,
      downloads: post.downloads.reduce(
        (pre, curr) => ({
          ...pre,
          [curr.filehost]: curr.url,
        }),
        {},
      ),
      remark: post.remark,
    };
  };

  public static starletFormInitialValues = (starlet: IStarlet) => {
    return {
      name: starlet.name,
      dob: starlet.dob,
      aka: starlet.aka,
      nativeName: starlet.nativeName,
      tags: starlet.tags,
    };
  };

  public static includes(list: Array<string>, item: string) {
    return list.map((el) => el.toLowerCase()).includes(item.toLowerCase());
  }

  public static htmlToBBCode = (html: string) => {
    html = html.replace(/<pre(.*?)>(.*?)<\/pre>/gim, "[code]$2[/code]");

    html = html.replace(/<h[1-7](.*?)>(.*?)<\/h[1-7]>/, "\n[h]$2[/h]\n");

    //paragraph handling:
    //- if a paragraph opens on the same line as another one closes, insert an extra blank line
    //- opening tag becomes two line breaks
    //- closing tags are just removed
    // html += html.replace(/<\/p><p/<\/p>\n<p/gi;
    // html += html.replace(/<p[^>]*>/\n\n/gi;
    // html += html.replace(/<\/p>//gi;

    html = html.replace(/<br(.*?)>/gi, "\n");
    html = html.replace(
      /<textarea(.*?)>(.*?)<\/textarea>/gim,
      "[code]$2[/code]",
    );
    html = html.replace(/<b>/gi, "[b]");
    html = html.replace(/<i>/gi, "[i]");
    html = html.replace(/<u>/gi, "[u]");
    html = html.replace(/<\/b>/gi, "[/b]");
    html = html.replace(/<\/i>/gi, "[/i]");
    html = html.replace(/<\/u>/gi, "[/u]");
    html = html.replace(/<em>/gi, "[b]");
    html = html.replace(/<\/em>/gi, "[/b]");
    html = html.replace(/<strong>/gi, "[b]");
    html = html.replace(/<\/strong>/gi, "[/b]");
    html = html.replace(/<cite>/gi, "[i]");
    html = html.replace(/<\/cite>/gi, "[/i]");
    html = html.replace(
      /<font color="(.*?)">(.*?)<\/font>/gim,
      "[color=$1]$2[/color]",
    );
    html = html.replace(
      /<font color=(.*?)>(.*?)<\/font>/gim,
      "[color=$1]$2[/color]",
    );
    html = html.replace(/<link(.*?)>/gi, "");
    html = html.replace(/<li(.*?)>(.*?)<\/li>/gi, "[*]$2");
    html = html.replace(/<ul(.*?)>/gi, "[list]");
    html = html.replace(/<\/ul>/gi, "[/list]");
    html = html.replace(/<div>/gi, "\n");
    html = html.replace(/<\/div>/gi, "\n");
    html = html.replace(/<td(.*?)>/gi, " ");
    html = html.replace(/<tr(.*?)>/gi, "\n");

    html = html.replace(/<img(.*?)src="(.*?)"(.*?)>/gi, "[img]$2[/img]");
    html = html.replace(
      /<a(.*?)href="(.*?)"(.*?)>(.*?)<\/a>/gi,
      "[url=$2]$4[/url]",
    );

    html = html.replace(/<head>(.*?)<\/head>/gim, "");
    html = html.replace(/<object>(.*?)<\/object>/gim, "");
    html = html.replace(/<script(.*?)>(.*?)<\/script>/gim, "");
    html = html.replace(/<style(.*?)>(.*?)<\/style>/gim, "");
    html = html.replace(/<title>(.*?)<\/title>/gim, "");
    html = html.replace(/<!--(.*?)-->/gim, "\n");

    html = html.replace(/\/\//gi, "/");
    html = html.replace(/http:\//gi, "http://");
    html = html.replace(/https:\//gi, "https://");

    html = html.replace(/<(?:[^>'"]*|(['"]).*?\1)*>/gim, "");
    html = html.replace(/\r\r/gi, "");
    html = html.replace(/\[img]\//gi, "[img]");
    html = html.replace(/\[url=\//gi, "[url=");

    html = html.replace(/(\S)\n/gi, "$1 ");

    return html;
  };

  public static formatFileSize = (zipSize: number) => {
    const fileSize = Number.parseFloat(
      (zipSize / (zipSize < 1024 ? 1 : 1024)).toFixed(2),
    );
    return `${fileSize}${zipSize < 1024 ? "MB" : "GB"}`;
  };

  public static formStarletName = (name: string, nativeName?: string) => {
    if (nativeName) {
      return `${name} ${nativeName}`;
    }
    return name;
  };
}

export default Utils;

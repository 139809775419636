import React from "react";
import { Form, Input } from "antd";
import { FILE_HOSTS } from "../consts/consts";
import ValidationUtils from "../utils/ValidationUtils";

const filehosts = Object.entries(FILE_HOSTS).map(([key, label]) => ({
  key,
  label,
}));

interface IProps {
  onChange?: (key: string, url: string) => void;
}

const FileHosts = (props: IProps) => {
  return (
    <>
      {filehosts.map(({ label, key }) => {
        return (
          <Form.Item
            key={key}
            label={label}
            name={["downloads", key]}
            rules={[
              {
                validateTrigger: "submit",
                validator: (rule, value) => {
                  if (value && !ValidationUtils.isValidUrl(value)) {
                    return Promise.reject(
                      "Filehost download link must be a valid url.",
                    );
                  }
                  if (
                    value &&
                    !ValidationUtils.isValidFilehostUrl(key, value)
                  ) {
                    return Promise.reject(
                      "Download link does not match with filehost.",
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
        );
      })}
    </>
  );
};

export default FileHosts;

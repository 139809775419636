import {
  Button,
  Col,
  Collapse,
  Dropdown,
  Pagination,
  Popover,
  Row,
  Space,
  Table,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import IconCheck from "../../assets/icons/IconCheck";
import IconCopy from "../../assets/icons/IconCopy";
import IconEdit from "../../assets/icons/IconEdit";
import IconFilter from "../../assets/icons/IconFilter";
import IconView from "../../assets/icons/IconView";
import PageContainer from "../../components/PageContainer";
import PostsFilters from "../../components/PostsFilters";
import SkeletonTable, {
  SkeletonTableColumnsType,
} from "../../components/SkeletonTable";
import {
  FILE_HOSTS_SHORT_NAME,
  POST_STATUS_LABEL_MAP,
  REMARKS,
} from "../../consts/consts";
import { postSlice } from "../../ducks/postDuck/postDuck";
import { GetPostsData, IFileHost, IPost } from "../../types/Post";
import IReactState from "../../types/ReactState";
import DateUtils from "../../utils/DateUtils";
import Utils from "../../utils/Utils";

const PostTitle = (props: IPost) => {
  const { _id: postId, title, content } = props;
  let popoverContent = <span>No Preview Images</span>;
  const imageTags = content.match(/<img [^>]*src="[^"]*"[^>]*>/gm);
  if (imageTags) {
    const images = imageTags
      .map((x) => x.replace(/.*src="([^"]*)".*/, "$1"))
      .slice(0, 6)
      .map((src, index) => (
        <div key={index}>
          <img
            className="w-32 aspect-[2/3] object-cover object-center rounded-sm"
            src={src}
            alt=""
          />
        </div>
      ));

    popoverContent = <Space direction="horizontal">{images}</Space>;
  }

  return (
    <div>
      <Popover
        placement="bottom"
        title="Preview Images"
        content={popoverContent}
        trigger="hover"
      >
        <Link to={`/posts/view/${postId}`}>{title}</Link>
      </Popover>
    </div>
  );
};

const PostCover = ({ cover }: { cover: string }) => {
  return (
    <Popover
      placement="bottom"
      content={
        <div className="text-center">
          <img
            className="w-44 aspect-[3/4] object-cover rounded-sm"
            src={cover}
            alt=""
          />
        </div>
      }
      trigger="hover"
    >
      <div className="text-center cursor-pointer">
        <img
          className="w-6   aspect-[3/4] object-cover rounded-sm"
          src={cover}
          alt=""
        />
      </div>
    </Popover>
  );
};

const Downloads = ({ downloads }: { downloads: Array<IFileHost> }) => {
  const links = downloads.map(({ filehost, url }, index) => {
    return (
      <div key={index}>
        <a href={url} target="_blank" rel="noreferrer">
          {FILE_HOSTS_SHORT_NAME[filehost]}
        </a>
      </div>
    );
  });
  return <Space direction="horizontal">{links}</Space>;
};

const PostActions = ({ post }: { post: IPost }) => {
  const dispatch = useDispatch();

  const { _id: postId, title } = post;
  const { postRows } = useSelector((state: IReactState) => state.postState);
  const postRow = postRows[postId];

  useEffect(() => {
    if (!postRow) {
      return;
    }
    const { updatePostStatusByIdSuccess, updatePostStatusByIdError } = postRow;
    if (updatePostStatusByIdSuccess) {
      notification.success({
        key: postId,
        message: "Post Updated",
        description: `${title} successfully marked as release ready.`,
      });
    }
    if (updatePostStatusByIdError) {
      notification.error({
        key: postId,
        message: "Update Post Failed",
        description: "Something went wrong, failed to update post.",
      });
    }
    return () => {
      dispatch(postSlice.actions.updatePostStatusByIdResetAction());
    };
  }, [dispatch, postId, title, postRow]);

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(Utils.postToString(post));
    notification.info({
      message: "Post Copied",
      description: `${title} copied to clipboard.`,
      duration: 2,
    });
  };

  const markAsReady = () => {
    notification.open({
      key: postId,
      message: "Updating Post",
      description: `Updating ${title}, please wait...`,
      duration: 0,
    });
    dispatch(
      postSlice.actions.updatePostStatusByIdAction({
        key: postId,
        postId,
        status: "RELEASE_READY",
      }),
    );
  };

  const items = [
    {
      key: "1",
      label: (
        <Space onClick={markAsReady}>
          <div className="h-4">
            <IconCheck />
          </div>
          <div>Mark As Ready</div>
        </Space>
      ),
    },
    {
      key: "2",
      label: (
        <Space onClick={copyToClipBoard}>
          <div className="h-4">
            <IconCopy />
          </div>
          <div>Copy Post</div>
        </Space>
      ),
    },
    {
      key: "3",
      label: (
        <Link to={`/posts/edit/${postId}`}>
          <Space>
            <div className="h-4">
              <IconEdit />
            </div>
            <div>Edit Post</div>
          </Space>
        </Link>
      ),
    },
    {
      key: "4",
      label: (
        <Link to={`/posts/view/${postId}`}>
          <Space>
            <div className="h-4">
              <IconView />
            </div>
            <div>View Post</div>
          </Space>
        </Link>
      ),
    },
  ];
  return (
    <Dropdown menu={{ items }} placement="bottom">
      <Button>Actions</Button>
    </Dropdown>
  );
};

const Posts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    pageSize: 50,
    currentPage: 1,
  });
  const [postsFilters, setPostFilters] = useState<GetPostsData>();
  const [columnsKeys, setColumns] = useState([
    "studio",
    "starlets",
    "title",
    "imagesCount",
    "releaseDate",
    "downloads",
    "status",
  ]);
  const [activeKey, setActiveKey] = useState(0);

  useEffect(() => {
    dispatch(
      postSlice.actions.fetchPostsAction({
        ...(postsFilters || {}),
        ...pagination,
      }),
    );
    setActiveKey(0);
  }, [dispatch, pagination, postsFilters]);

  const { posts, totalPosts, fetchPostsLoading } = useSelector(
    (state: IReactState) => state.postState,
  );

  const { currentPage, pageSize } = pagination;
  const start = pageSize * (currentPage - 1);
  let end = pageSize * (currentPage - 1) + pageSize;
  end = Math.min(end, totalPosts);

  const columns = [
    {
      title: "Cover",
      dataIndex: "cover",
      key: "cover",
      render: (cover: string) => <PostCover cover={cover} />,
    },
    {
      title: "Studio",
      dataIndex: "studio",
      key: "studio",
    },
    {
      title: "Starlets",
      dataIndex: "starlets",
      key: "starlets",
    },
    {
      title: "Title",
      key: "title",
      dataIndex: "post",
      render: (data: IPost) => <PostTitle {...data} />,
    },
    {
      title: "Images",
      dataIndex: "imagesCount",
      key: "imagesCount",
    },
    {
      title: "Zip Size",
      dataIndex: "zipSize",
      key: "zipSize",
    },
    {
      title: <span className="whitespace-nowrap">Released On</span>,
      dataIndex: "releaseDate",
      key: "releaseDate",
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "Downloads",
      dataIndex: "downloads",
      key: "downloads",
      render: (downloads: Array<IFileHost>) => (
        <Downloads downloads={downloads} />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
    },
    {
      title: <span className="whitespace-nowrap">Created By</span>,
      dataIndex: "createdBy",
      key: "createdBy",
      width: "10%",
    },
    {
      title: <span className="whitespace-nowrap">Created On</span>,
      dataIndex: "createdAt",
      key: "createdAt",
      width: "10%",
    },
    {
      title: "",
      dataIndex: "post",
      key: "action",
      render: (post: IPost) => <PostActions post={post} />,
    },
  ].filter(({ key }) => columnsKeys.includes(key) || key === "action");

  const dataSource = posts.map((post) => {
    const {
      _id: postId,
      studio,
      starlets,
      title,
      releaseDate,
      imagesCount,
      downloads,
      content,
      cover,
      status,
      remark,
      zipSize,
      createdBy,
      createdAt,
    } = post;
    return {
      key: postId,
      postId,
      title,
      imagesCount,
      content,
      cover,
      downloads,
      releaseDate: releaseDate || "NA",
      status: POST_STATUS_LABEL_MAP[status],
      studio: studio.name,
      starlets: [...starlets]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ name, nativeName }) => {
          if (nativeName) {
            return `${name} ${nativeName}`;
          }
          return name;
        })
        .join(", "),
      remark: REMARKS[remark] || "NA",
      zipSize: Utils.formatFileSize(zipSize),
      createdBy: createdBy.username,
      createdAt: DateUtils.getFormattedDate(createdAt),
      post,
    };
  });

  const onClickCreatePost = () => {
    navigate("/posts/create");
  };

  const actions = [
    {
      name: "Create Post",
      onClick: onClickCreatePost,
    },
  ];

  return (
    <PageContainer title="Posts" actions={actions}>
      <div>
        <Collapse bordered={false} activeKey={activeKey} collapsible="icon">
          <Collapse.Panel
            showArrow={false}
            header={
              <Row justify="space-between">
                <Col>{activeKey === 1 && <span>Post Filters</span>}</Col>
                <Col>
                  <Button
                    icon={<IconFilter />}
                    size="large"
                    onClick={() => setActiveKey(activeKey === 0 ? 1 : 0)}
                  />
                </Col>
              </Row>
            }
            key="1"
          >
            <PostsFilters setFilters={setPostFilters} setColumns={setColumns} />
          </Collapse.Panel>
        </Collapse>
        <SkeletonTable
          active={fetchPostsLoading}
          loading={fetchPostsLoading}
          columns={columns as SkeletonTableColumnsType[]}
          rowCount={pagination.pageSize}
        >
          <Table
            size="small"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
          />
        </SkeletonTable>
        <div className="flex justify-between px-4 pt-4">
          <div>
            <span className="text-white">
              Showing {start}-{end} of {totalPosts}
            </span>
          </div>
          <div>
            <Pagination
              defaultCurrent={1}
              current={pagination.currentPage}
              total={totalPosts}
              pageSize={pagination.pageSize}
              onChange={(page, pageSize) => {
                setPagination({
                  pageSize,
                  currentPage: page,
                });
              }}
            />
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default Posts;

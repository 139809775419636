import { Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import PostView from "../../components/PostView";
import { postSlice } from "../../ducks/postDuck/postDuck";
import IReactState from "../../types/ReactState";

const PostsView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { postId } = params;

  const {
    post,
    fetchPostByIdLoading,
    fetchPostByIdSuccess,
    fetchPostByIdError,
  } = useSelector((state: IReactState) => state.postState);

  useEffect(() => {
    dispatch(postSlice.actions.fetchPostByIdAction(postId));
  }, [dispatch, postId]);

  const actions = [
    {
      name: "Edit Post",
      onClick: () => navigate("/posts/edit/" + postId),
    },
  ];

  return (
    <PageContainer title="View Post" actions={actions}>
      <>
        {fetchPostByIdLoading && (
          <div className="flex flex-col items-center justify-center h-full w-full ">
            <div className="text-center">
              <Spin size="large" />
              <h5 className="text-rose-700">Loading Please Wait...</h5>
            </div>
          </div>
        )}
        {post && <PostView post={post} />}
      </>
    </PageContainer>
  );
};

export default PostsView;

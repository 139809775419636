import { all } from "redux-saga/effects";
import { watcherSaga as authSaga } from "./authDuck/authDuck";
import { watcherSaga as starletSaga } from "./starletDuck/starletDuck";
import { watcherSaga as studioSaga } from "./studioDuck/studioDuck";
import { watcherSaga as postSaga } from "./postDuck/postDuck";
import { watcherSaga as userSaga } from "./userDuck/userDuck";
import { watcherSaga as tagSaga } from "./tagDuck/tagDuck";
import { watcherSaga as dashboardSaga } from "./dashboardDuck/dashboardDuck";
import { watcherSaga as appSaga } from "./appDuck/appDuck";
import { watcherSaga as reportSaga } from "./reportDuck/reportDuck";

const rootSaga = function* (): Generator {
  yield all([
    authSaga(),
    userSaga(),
    starletSaga(),
    studioSaga(),
    tagSaga(),
    postSaga(),
    dashboardSaga(),
    appSaga(),
    reportSaga(),
  ]);
};

export default rootSaga;

import {
  ICreatePostRequestData,
  IGetPostsRequestData,
  IGetPostsResponseData,
  IPost,
  IUpdatePostRequestData,
} from "../types/Post";
import API from "./API";

const api = new API();

export const createPost = async (
  postData: ICreatePostRequestData,
): Promise<IPost> => {
  return api
    .callApi({
      url: "/posts",
      method: "POST",
      data: postData,
    })
    .then(({ data }) => data.data);
};

export const fetchPosts = async (
  data: IGetPostsRequestData,
): Promise<IGetPostsResponseData> => {
  return api
    .callApi({
      url: "/posts",
      method: "GET",
      params: data,
    })
    .then(({ data }) => data.data);
};

export const fetchPostById = async (postId: string): Promise<IPost> => {
  return api
    .callApi({
      url: `/posts/${postId}`,
      method: "GET",
    })
    .then(({ data }) => data.data);
};

export const updatePostById = async (
  postId: string,
  postData: IUpdatePostRequestData,
): Promise<IPost> => {
  return api
    .callApi({
      url: `/posts/${postId}`,
      method: "PUT",
      data: postData,
    })
    .then(({ data }) => data.data);
};

export const updatePostStatusById = async (
  postId: string,
  status: string,
): Promise<IPost> => {
  return api
    .callApi({
      url: `/posts/${postId}/status`,
      method: "PUT",
      data: { status },
    })
    .then(({ data }) => data.data);
};

const PostAPI = {
  createPost,
  fetchPosts,
  fetchPostById,
  updatePostById,
  updatePostStatusById,
};

export default PostAPI;

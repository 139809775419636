import { IPageViewsStats } from "../types/Dashboard";
import API from "./API";

const api = new API();

export const fetchStats = async (): Promise<IPageViewsStats> => {
  return api
    .callApi({
      url: "/page-views/stats",
      method: "GET",
    })
    .then(({ data }) => data.data);
};

const DashboardAPI = {
  fetchStats,
};

export default DashboardAPI;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import PageSpinner from "../components/PageSipnner";
import { authSlice } from "../ducks/authDuck/authDuck";
import IReactState from "../types/ReactState";

const Home = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const authState = useSelector((state: IReactState) => state.authState);
  const userToken = localStorage.getItem("user-token");

  useEffect(() => {
    if (userToken && !authState.isAuthenticated) {
      dispatch(authSlice.actions.userMeAction());
    }
  });

  if (userToken && authState.isAuthenticated) {
    return (
      <Navigate to="/dashboard" replace={true} state={{ from: location }} />
    );
  }

  if (authState.userMeError) {
    return <Navigate to="/login" replace={true} state={{ from: location }} />;
  }

  return <PageSpinner />;
};

export default Home;

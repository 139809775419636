import React from "react";
import { Select, SelectProps } from "antd";
import { CATEGORIES } from "../../consts/consts";

const categoryOptions = Object.entries(CATEGORIES).map(([value, label]) => ({
  value,
  label,
}));

const CategorySelector = (props: SelectProps) => {
  return (
    <Select
      size="large"
      placeholder="Select collection category..."
      options={categoryOptions}
      {...props}
    />
  );
};

export default CategorySelector;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Navigate, useLocation } from "react-router-dom";
import PageSpinner from "../components/PageSipnner";
import { authSlice } from "../ducks/authDuck/authDuck";
import { IReactState } from "../types/ReactState";

interface IProps {
  children: JSX.Element;
}

function PrivateRoute({ children }: IProps): JSX.Element {
  const dispatch = useDispatch();
  const location = useLocation();

  const authState = useSelector((state: IReactState) => state.authState);
  const userToken = localStorage.getItem("user-token");

  useEffect(() => {
    if (userToken && !authState.isAuthenticated) {
      dispatch(authSlice.actions.userMeAction());
    }
  });

  if (userToken && authState.userMeLoading) {
    return <PageSpinner />;
  }

  if (!authState.isAuthenticated || authState.userMeError) {
    return <Navigate to="/login" replace={true} state={{ from: location }} />;
  }

  return children;
}

export default PrivateRoute;

import React, { useMemo } from "react";
import { Button, Col, Row } from "antd";
import { BACKGROUNDS_IMAGES } from "../consts/consts";
import Utils from "../utils/Utils";

const ErrorFallback = () => {
  const backgroundImage = useMemo(() => {
    return Utils.shuffleArray(BACKGROUNDS_IMAGES.errorPage).at(0);
  }, []);

  return (
    <div>
      <div className="absolute flex w-full h-full top-0 bottom-0 left-0 right-0 z-10">
        <img
          className="w-full object-cover object-center bg-black"
          src={backgroundImage}
          alt=""
        />
      </div>
      <div className="relative z-20">
        <Row justify="end" align="middle" className="h-screen">
          <Col md={10} className="text-rose-700 text-center">
            <h1 className="text-6xl fw-extrabold">Ooops...</h1>
            <h2>Something went wrong!</h2>
            <h3 className="text-gray-700">
              Error is logged to the server, please{" "}
              <a className="text-rose-600 no-underline" href="/">
                click here
              </a>{" "}
              to go back.
            </h3>
            <div>
              <a href="/">
                <Button className="w-3/4" type="primary" size="large">
                  Go Black
                </Button>
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </div>

    // <div className="h-full flex flex-col items-center justify-center text-rose-600 text-center">
    //   <div>
    //   <h1 className="text-6xl fw-extrabold">Ooops...</h1>
    //     <h1>Something went wrong!</h1>
    //     <h2 className="text-gray-700">
    //       Error is logged to the server, please{" "}
    //       <a className="text-rose-600 no-underline" href="/">
    //         click here
    //       </a>{" "}
    //       to go back.
    //     </h2>
    //     <div className="">
    //       <a href="/">
    //         <Button block type="primary" size="large">
    //           Go Black
    //         </Button>
    //       </a>
    //     </div>
    //   </div>
    // </div>
  );
};

export default ErrorFallback;

import { Button, Col, Row } from "antd";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { captureSentryException } from "../config/Sentry";
import { BACKGROUNDS_IMAGES } from "../consts/consts";
import Utils from "../utils/Utils";

const NotFound = () => {
  const location = useLocation();

  captureSentryException("AppError", new Error("Page not found"), {
    ...location,
  });

  const backgroundImage = useMemo(() => {
    return Utils.shuffleArray(BACKGROUNDS_IMAGES.noFoundPage).at(0);
  }, []);
  return (
    <div>
      <div className="absolute flex w-full h-full top-0 bottom-0 left-0 right-0 z-10">
        <img
          className="w-full object-cover object-center bg-black"
          src={backgroundImage}
          alt=""
        />
      </div>
      <div className="relative z-20">
        <Row justify="end" align="middle" className="h-screen">
          <Col md={10} className="text-rose-700 text-center">
            <h1 className="text-6xl fw-extrabold">404!</h1>
            <h2>The page you visited does not exist!</h2>
            <h3 className="text-gray-700">
              Whatever you are looking for does not exist.{" "}
              <a className="text-rose-600 no-underline" href="/">
                click here
              </a>{" "}
              to go back.
            </h3>
            <div>
              <a href="/">
                <Button className="w-3/4" type="primary" size="large">
                  Go Black
                </Button>
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NotFound;

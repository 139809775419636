import React, { MutableRefObject, useEffect } from "react";
import { Col, Row, Typography, theme, Space, Tag } from "antd";
import { CreatePostData, IPost } from "../types/Post";
import PostCard from "./PostCard";
import { useDispatch, useSelector } from "react-redux";
import { postSlice } from "../ducks/postDuck/postDuck";
import IReactState from "../types/ReactState";
import * as DOMPurify from "dompurify";
import Utils from "../utils/Utils";

interface IProps {
  postData: Partial<CreatePostData>;
  imagesRef?: MutableRefObject<HTMLDivElement | null>;
}

const PostPreview = (props: IProps) => {
  const dispatch = useDispatch();
  const {
    token: { colorBgContainer, colorPrimary },
  } = theme.useToken();

  const { posts, fetchPostsLoading } = useSelector(
    (state: IReactState) => state.postState,
  );

  useEffect(() => {
    dispatch(
      postSlice.actions.fetchPostsAction({
        pageSize: 10,
        currentPage: 1,
      }),
    );
  }, [dispatch]);

  const { postData, imagesRef } = props;
  const title: Array<string> = [];
  const cardTitle: Array<string> = [];
  const releaseDate = postData.releaseDate || "NA";
  const zipSize = parseFloat(`${postData.zipSize}`);
  if (postData.studio?.name) {
    title.push(postData.studio.name);
    cardTitle.push(postData.studio.name);
  } else {
    title.push("Dummy Studio");
  }

  if (postData?.starlets) {
    const starlets = postData.starlets
      .map(({ name, nativeName }) => {
        if (nativeName) {
          return `${name} ${nativeName || ""}`;
        }
        return name;
      })
      .join(", ");
    title.push(starlets);
    cardTitle.push(starlets);
  } else {
    title.push("Dummy Starlet");
  }
  if (postData.title) {
    title.push(Utils.capitalize(postData.title).replace(/\s\s+/g, " "));
  } else {
    title.push("Dummy Post Title For Preview");
  }

  const sanitizedContent = DOMPurify.sanitize(`${postData.content}`, {
    USE_PROFILES: { html: true },
  });

  const sanitizedDescription = DOMPurify.sanitize(`${postData.description}`, {
    USE_PROFILES: { html: true },
  });

  const previewPosts = [postData, ...posts.slice(0, 7)];
  return (
    <div>
      <div className="pb-5">
        <div
          style={{
            background: colorBgContainer,
          }}
          className="px-8 pb-8 rounded-lg"
        >
          <div className="pb-4 pt-1">
            <Typography.Title level={4}>Card Preview</Typography.Title>
          </div>
          <Row gutter={[10, 15]}>
            {previewPosts.map((postData, key) => {
              const {
                title,
                cover,
                starlets,
                studio,
                releaseDate,
                createdBy,
                createdAt,
                remark,
              } = postData as IPost;
              return (
                <Col key={key} span={6}>
                  <PostCard
                    coverImageUrl={cover || ""}
                    title={Utils.capitalize(
                      [
                        studio?.name || "Dummy Studio",
                        starlets
                          ?.map(({ name, nativeName }) => {
                            if (nativeName) {
                              return `${name} ${nativeName || ""}`;
                            }
                            return name;
                          })
                          .join(", ") || "Dummy Starlet",
                        (title || "Dummy Post Title For Preview").replace(
                          /\s\s+/g,
                          " ",
                        ),
                      ].join(" — "),
                    )}
                    releaseDate={releaseDate || "NA"}
                    createdBy={createdBy}
                    createdAt={createdAt}
                    remark={remark}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
      <div className="pb-8">
        <div
          style={{
            background: colorBgContainer,
          }}
          className="px-8 pb-8 rounded-lg"
        >
          <div className="pb-4 pt-1">
            <Typography.Title level={4}>Post Preview</Typography.Title>
          </div>
          <div className="text-center">
            <Typography.Title>{title.join(" — ")}</Typography.Title>
            {postData.description && (
              <Typography.Title level={5}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitizedDescription,
                  }}
                ></span>
              </Typography.Title>
            )}
            <Typography.Title level={5}>
              <span className="text-gray-600">Images Count:</span>{" "}
              {postData.imagesCount || "NA"}{" "}
              <span className="text-gray-600">Resolution:</span>{" "}
              {postData.imageWidth && postData.imageHeight
                ? `${postData.imageWidth}x${postData.imageHeight}`
                : "NA"}{" "}
              <span className="text-gray-600">Release Date:</span> {releaseDate}
            </Typography.Title>
            {postData.content ? (
              <div
                ref={imagesRef}
                dangerouslySetInnerHTML={{
                  __html: sanitizedContent,
                }}
              />
            ) : (
              <div className="text-gray-600 text-lg p-10">No Images Links</div>
            )}
          </div>
          <div>
            <div className="pb-4 pt-1">
              <Typography.Title level={4}>Downloads</Typography.Title>
            </div>
            <div>
              <Typography.Title level={5}>
                Zip File Size: {Utils.formatFileSize(zipSize)}
              </Typography.Title>
              {postData.downloads?.map(({ filehost, url }) => {
                return (
                  <Row key={filehost}>
                    <Col span={3}>
                      <Typography.Title level={5}>{filehost}</Typography.Title>
                    </Col>
                    <Col span={21}>
                      <Typography.Title level={5}>
                        <a href={url}>{url}</a>
                      </Typography.Title>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </div>
          <div>
            <div className="pb-4 pt-1">
              <Typography.Title level={4}>Studio</Typography.Title>
            </div>
            <Tag color={colorPrimary}>{postData?.studio?.name}</Tag>
          </div>
          <div>
            <div className="pb-4 pt-1">
              <Typography.Title level={4}>Starlets</Typography.Title>
            </div>
            <Space size="small">
              {postData?.starlets?.map(({ name }, index) => (
                <Tag color={colorPrimary} key={index}>
                  {name}
                </Tag>
              ))}
            </Space>
          </div>
          <div>
            <div className="pb-4 pt-1">
              <Typography.Title level={4}>Tags</Typography.Title>
            </div>
            <Space size="small" wrap>
              {postData?.tags?.map(({ name }, index) => (
                <Tag color={colorPrimary} key={index}>
                  {name}
                </Tag>
              ))}
            </Space>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostPreview;

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import config from "../config/config";
import { IUser } from "../types/User";

const { stage, sentryDns } = config;
const isSentryEnabled = sentryDns;

export const initSentry = (): void => {
  if (!isSentryEnabled) {
    return;
  }
  Sentry.init({
    environment: stage,
    dsn: sentryDns,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
};

export const setSentryUser = (user: IUser): void => {
  if (!isSentryEnabled) {
    return;
  }
  Sentry.setUser({
    id: user._id,
    email: user.email,
    username: user.username,
    userRole: user.userRole,
  });
};

export const captureSentryException = (
  errorType: string,
  error: Error,
  extra: { [key: string]: any },
): void => {
  if (!isSentryEnabled) {
    return;
  }
  Sentry.withScope((scope) => {
    scope.setExtra("ErrorType", "AppError");
    Object.entries(extra).forEach(([key, value]) => {
      scope.setExtra(key, value);
    });
    Object.entries(error).forEach(([key, value]) => {
      scope.setExtra(key, value);
    });
    Sentry.captureException(error);
  });
};

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notification, Select, SelectProps } from "antd";
import { studioSlice } from "../../ducks/studioDuck/studioDuck";
import IReactState from "../../types/ReactState";

interface IOption {
  label: string;
  value: string;
}

interface IProps extends SelectProps {
  type?: "OWNERS" | "ALL";
}

const StudioSelector = (props: IProps) => {
  const { type, value, onChange } = props;
  const [retry, setRetry] = useState(0);

  const dispatch = useDispatch();

  const studioState = useSelector((state: IReactState) => state.studioState);
  const { studios, fetchStudiosError } = studioState;
  useEffect(() => {
    if (fetchStudiosError) {
      if (retry >= 5) {
        notification.error({
          message: "Something went wrong!",
          description:
            "Failed to fetch studios, please click on dropdown and retry.",
        });
      } else {
        dispatch(studioSlice.actions.fetchStudiosAction());
        setRetry(retry + 1);
      }
      return;
    }
    dispatch(studioSlice.actions.fetchStudiosAction());
  }, [dispatch, fetchStudiosError, retry]);

  const defaultValues = value && {
    label: value.name,
    value: value._id,
  };

  const options = useMemo(() => {
    return [...studios]
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter((studio) => {
        if (type === "OWNERS") {
          return !studio.ownerStudio;
        }
        return true;
      })
      .map((studio) => ({
        label: studio.name,
        value: studio._id,
      }));
  }, [studios, type]);

  const handleChange = (value: string) => {
    if (onChange) {
      onChange(
        studioState.studios.find(({ _id }) => value === _id),
        [],
      );
    }
  };

  const filterOption = (input: string, option: IOption | undefined) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const filterSort = (optionA: IOption, optionB: IOption) =>
    (optionA?.label ?? "")
      .toLowerCase()
      .localeCompare((optionB?.label ?? "").toLowerCase());

  return (
    <Select
      showSearch
      allowClear
      size="large"
      placeholder="Select studio..."
      value={defaultValues}
      options={options}
      loading={studioState.fetchStudiosLoading}
      onChange={handleChange}
      filterOption={filterOption}
      filterSort={filterSort}
      dropdownRender={(menu) => {
        return (
          <>
            {menu}
            {fetchStudiosError && (
              <div
                className="p-4 text-center cursor-pointer text-rose-900"
                onClick={() => setRetry(0)}
              >
                <span>Click Here To Retry</span>
              </div>
            )}
          </>
        );
      }}
    />
  );
};

export default StudioSelector;

import { ConfigProvider, theme } from "antd";
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import "./assets/scss/main.scss";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./pages/ErrorFallback";
import { useDispatch, useSelector } from "react-redux";
import IReactState from "./types/ReactState";
import { appSlice } from "./ducks/appDuck/appDuck";
import {
  captureSentryException,
  initSentry,
  setSentryUser,
} from "./config/Sentry";

initSentry();

const themeData = {
  borderRadius: 6,
  colorPrimary: "#E11D48",
  colorLink: "#E11D48",
  colorLinkHover: "#D7405C",
  colorLinkActive: "#D7405C",
  fontFamily: "proxima-nova-extrabold",
};

const App = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: IReactState) => state.authState);
  if (user) {
    setSentryUser(user);
  }
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(appSlice.actions.pingAction());
    }, 1000 * 60 * 2);
    return () => clearInterval(interval);
  }, [dispatch]);

  const logError = (error: Error, info: { componentStack: string }) => {
    captureSentryException("AppError", error, {
      ComponentStack: info.componentStack,
    });
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          ...themeData,
        },
      }}
    >
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ErrorBoundary>
    </ConfigProvider>
  );
};

export default App;

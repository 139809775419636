import React, { useEffect } from "react";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "../../components/PageContainer";
import { starletSlice } from "../../ducks/starletDuck/starletDuck";
import IReactState from "../../types/ReactState";
import { CreateStarletData } from "../../types/Starlet";
import StarletFrom from "../../components/StarletForm";

const StarletsCreate = () => {
  const dispatch = useDispatch();
  const starletState = useSelector((state: IReactState) => state.starletState);
  const {
    starlet,
    createStarletLoading,
    createStarletSuccess,
    createStarletError,
    createStarletErrorMessage,
  } = starletState;

  useEffect(() => {
    if (createStarletSuccess) {
      notification.success({
        message: "Starlet Created",
        description: `${starlet?.name} successfully created.`,
      });
    }
    if (createStarletError) {
      notification.error({
        message: "Create Starlet Failed",
        description:
          createStarletErrorMessage ||
          "Something went wrong, failed to create starlet.",
      });
    }
    return () => {
      dispatch(starletSlice.actions.createStarletResetAction());
    };
  }, [
    dispatch,
    starlet,
    createStarletSuccess,
    createStarletError,
    createStarletErrorMessage,
  ]);

  const createStarlet = (starletData: CreateStarletData) => {
    dispatch(starletSlice.actions.createStarletAction(starletData));
  };

  return (
    <PageContainer title="Create Starlet">
      <StarletFrom
        isEdit={false}
        isSuccess={createStarletSuccess}
        loading={createStarletLoading}
        onSubmit={createStarlet}
      />
    </PageContainer>
  );
};

export default StarletsCreate;

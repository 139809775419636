import { Typography, Col, Row, theme, Badge, Space, Tag } from "antd";
import React from "react";
import { REMARKS } from "../consts/consts";
import { IPost } from "../types/Post";

interface IProps {
  post: IPost;
}

const PostView = (props: IProps) => {
  const {
    token: { colorBgContainer, colorPrimary },
  } = theme.useToken();

  const { post } = props;
  const {
    cover,
    studio,
    title,
    description,
    starlets,
    imageWidth,
    imageHeight,
    imagesCount,
    releaseDate,
    tags,
    downloads,
    zipSize,
    remark,
    content,
  } = post;
  return (
    <div>
      <div
        style={{
          background: colorBgContainer,
        }}
        className="px-8 pb-8 rounded-lg"
      >
        <div className="pb-4 pt-1">
          <Typography.Title level={4}>Post View</Typography.Title>
        </div>
        <Row>
          <Col span={8}>
            <img
              className="w-full aspect-[3/4] object-cover object-center rounded-lg"
              src={cover}
              alt=""
            />
          </Col>
          <Col span={16}>
            <div className="text-white/90 text-xl pl-4">
              <table className="">
                <tbody>
                  <tr>
                    <td className="py-2">
                      <span className="text-gray-500">Studio:</span>
                    </td>
                    <td className="py-2">{studio.name}</td>
                  </tr>
                  <tr>
                    <td className="py-2">
                      <span className="text-gray-500">Starlets:</span>
                    </td>
                    <td className="py-2">
                      <Space size="small">
                        {starlets.map(({ name, nativeName }, index) => (
                          <span className="text-xl text-rose-600" key={index}>
                            {name} {nativeName || ""}
                          </span>
                        ))}
                      </Space>
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2">
                      <span className="text-gray-500">Title:</span>
                    </td>
                    <td className="py-2">{title}</td>
                  </tr>
                  <tr>
                    <td className="py-2">
                      <span className="text-gray-500">Description:</span>
                    </td>
                    <td className="py-2">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: description || "",
                        }}
                      ></div>
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2">
                      <span className="text-gray-500">Release Date:</span>
                    </td>
                    <td className="py-2">{releaseDate || "NA"}</td>
                  </tr>
                  <tr>
                    <td className="py-2">
                      <span className="text-gray-500">Resolution:</span>
                    </td>
                    <td className="py-2">
                      {imageWidth}x{imageHeight}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2">
                      <span className="text-gray-500 pr-2">Images Count:</span>
                    </td>
                    <td className="py-2">{imagesCount}</td>
                  </tr>
                  <tr>
                    <td className="py-2">
                      <span className="text-gray-500 pr-2">Tags:</span>
                    </td>
                    <td className="py-2">
                      <Space size="small" wrap>
                        {tags.map(({ name }, index) => (
                          <Tag color={colorPrimary} key={index}>
                            {name}
                          </Tag>
                        ))}
                      </Space>
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2">
                      <span className="text-gray-500">Downloads:</span>
                    </td>
                    <td className="py-2">
                      <Space size="small">
                        {downloads.map(({ filehost, url }, index) => (
                          <a
                            key={index}
                            href={url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span className="text-xl">{filehost}</span>
                          </a>
                        ))}
                      </Space>
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2">
                      <span className="text-gray-500 pr-2">Zip Size:</span>
                    </td>
                    <td className="py-2">
                      {zipSize / (zipSize < 1024 ? 1 : 1024)}{" "}
                      {zipSize < 1024 ? "MB" : "GB"}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2">
                      <span className="text-gray-500 pr-2">Remark:</span>
                    </td>
                    <td className="py-2">{REMARKS[remark] || "NA"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div></div>
          </Col>
        </Row>
        <div>
          <div className="pb-4 pt-1">
            <Typography.Title level={4}>Post Images</Typography.Title>
          </div>
          <div
            className="text-center"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PostView;

import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth, signInWithCustomToken } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDGVN0V_nmwxD32ZA8NU-7dJa7YIyen23E",
  authDomain: "redpics-17c29.firebaseapp.com",
  databaseURL: "https://redpics-17c29-default-rtdb.firebaseio.com",
  projectId: "redpics-17c29",
  storageBucket: "redpics-17c29.appspot.com",
  messagingSenderId: "334628339997",
  appId: "1:334628339997:web:c1a6797e103503076d21f4",
  measurementId: "G-5QEBC78LGL",
};

export class Firebase {
  public init() {
    initializeApp(firebaseConfig);
  }

  public authenticate(token: string) {
    const auth = getAuth();
    return signInWithCustomToken(auth, token);
  }

  public getDatabase() {
    return getDatabase();
  }
}

export default Firebase;

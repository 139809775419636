import { IUser } from "../types/User";
import API from "./API";

const api = new API();

export const login = async (data: object): Promise<IUser> => {
  return api
    .callApi({
      url: "/auth/login",
      method: "POST",
      data,
    })
    .then(({ data }) => data.data);
};

export const me = async (): Promise<IUser> => {
  return api
    .callApi({
      url: "/auth/me",
      method: "GET",
    })
    .then(({ data }) => data.data);
};

const AuthAPI = {
  login,
  me,
};

export default AuthAPI;

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notification, Select, SelectProps } from "antd";
import { starletSlice } from "../../ducks/starletDuck/starletDuck";
import IReactState from "../../types/ReactState";
import Utils from "../../utils/Utils";

interface IOption {
  label: string;
  value: string;
}

const StarletsSelector = (props: SelectProps) => {
  const { value, onChange } = props;
  const [retry, setRetry] = useState(0);

  const dispatch = useDispatch();

  const starletState = useSelector((state: IReactState) => state.starletState);
  const { starlets, fetchStarletsError } = starletState;
  useEffect(() => {
    if (fetchStarletsError) {
      if (retry >= 5) {
        notification.error({
          message: "Something went wrong!",
          description:
            "Failed to fetch starlets, please click on dropdown and retry.",
        });
      } else {
        dispatch(starletSlice.actions.fetchStarletsAction());
        setRetry(retry + 1);
      }
      return;
    }
    dispatch(starletSlice.actions.fetchStarletsAction());
  }, [dispatch, fetchStarletsError, retry]);

  const defaultValues = value?.map(
    ({
      _id,
      name,
      nativeName,
    }: {
      _id: string;
      name: string;
      nativeName: string;
    }) => ({
      label: Utils.formStarletName(name, nativeName),
      value: _id,
    }),
  );

  const options = useMemo(() => {
    return [...starlets]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((starlet) => ({
        label: Utils.formStarletName(starlet.name, starlet.nativeName),
        value: starlet._id,
      }));
  }, [starlets]);

  const handleChange = (values: string[]) => {
    const selectSet = new Set(values);
    const starlets = starletState.starlets.filter(({ _id }) =>
      selectSet.has(_id),
    );
    if (onChange) {
      onChange(starlets, []);
    }
  };

  const filterOption = (input: string, option: IOption | undefined) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const filterSort = (optionA: IOption, optionB: IOption) =>
    (optionA?.label ?? "")
      .toLowerCase()
      .localeCompare((optionB?.label ?? "").toLowerCase());

  return (
    <Select
      showSearch
      size="large"
      mode="multiple"
      placeholder="Select starlets..."
      value={defaultValues}
      options={options}
      loading={starletState.fetchStarletsLoading}
      onChange={handleChange}
      filterOption={filterOption}
      filterSort={filterSort}
      dropdownRender={(menu) => {
        return (
          <>
            {menu}
            {fetchStarletsError && (
              <div
                className="p-4 text-center cursor-pointer text-rose-900"
                onClick={() => setRetry(0)}
              >
                <span>Click Here To Retry</span>
              </div>
            )}
          </>
        );
      }}
    />
  );
};

export default StarletsSelector;

import { Button, Col, Pagination, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PageContainer from "../components/PageContainer";
import SkeletonTable, {
  SkeletonTableColumnsType,
} from "../components/SkeletonTable";
import { reportSlice } from "../ducks/reportDuck/reportDuck";
import { IPost } from "../types/Post";
import IReactState from "../types/ReactState";
import DateUtils from "../utils/DateUtils";

const Reports = () => {
  const dispatch = useDispatch();

  const [pagination, setPagination] = useState({
    pageSize: 50,
    currentPage: 1,
  });

  useEffect(() => {
    dispatch(reportSlice.actions.fetchReportsAction({ data: true }));
  }, [dispatch]);

  const { reports, fetchReportsLoading } = useSelector(
    (state: IReactState) => state.reportState,
  );

  const total = reports.length;
  const { currentPage, pageSize } = pagination;
  const start = pageSize * (currentPage - 1);
  let end = pageSize * (currentPage - 1) + pageSize;
  end = Math.min(end, total);

  const columns = [
    {
      title: "Report Type",
      dataIndex: "reportType",
      key: "reportType",
    },
    {
      title: "Post Title",
      dataIndex: "post",
      key: "photosetId",
      render: (post: IPost) => {
        if (post) {
          const { title, _id } = post;
          return <Link to={`/posts/view/${_id}`}>{title}</Link>;
        }
        return "NA";
      },
    },
    {
      title: "Reported By",
      dataIndex: "clientId",
      key: "clientId",
    },
    {
      title: "Reported On",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      render: () => (
        <Row>
          <Col>
            <Button type="link">Resolved</Button>
          </Col>
        </Row>
      ),
    },
  ];

  const dataSource = reports.map((report) => {
    const { post } = report;
    const {
      _id: reportId,
      reportType,
      clientId,
      photosetId,
      createdAt,
    } = report;
    return {
      key: reportId,
      reportId,
      reportType,
      clientId: clientId.slice(-10),
      photosetId,
      post: post,
      createdAt: DateUtils.getFormattedDate(createdAt),
    };
  });

  return (
    <PageContainer title="Reports">
      <div>
        <div>
          <SkeletonTable
            active={fetchReportsLoading}
            loading={fetchReportsLoading}
            columns={columns as SkeletonTableColumnsType[]}
            rowCount={pagination.pageSize}
          >
            <Table
              size="small"
              columns={columns}
              dataSource={dataSource}
              pagination={false}
            />
          </SkeletonTable>
        </div>
        <div>
          <div className="flex justify-between px-4 pt-4">
            <div>
              <span className="text-white">
                Showing {start}-{end} of {total}
              </span>
            </div>
            <div>
              <Pagination
                defaultCurrent={1}
                current={pagination.currentPage}
                total={total}
                pageSize={pagination.pageSize}
                onChange={(page, pageSize) => {
                  setPagination({
                    pageSize,
                    currentPage: page,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default Reports;

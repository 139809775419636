import { createSlice } from "@reduxjs/toolkit";
import {
  all,
  call,
  put,
  select,
  StrictEffect,
  takeLatest,
} from "redux-saga/effects";
import StudiosAPI from "../../api/StudiosAPI";
import {
  CreateStudioData,
  ICreateStudioRequestData,
  IStudio,
} from "../../types/Studio";
import IStudioState from "./types";
import { tagSlice } from "../tagDuck/tagDuck";
import { AxiosError } from "axios";
import IReactState from "../../types/ReactState";
import { captureSentryException } from "../../config/Sentry";

const initialState: IStudioState = {
  createStudioLoading: false,
  createStudioSuccess: false,
  createStudioError: false,
  createStudioErrorMessage: "",

  studios: [],
  fetchStudiosLoading: false,
  fetchStudiosSuccess: false,
  fetchStudiosError: false,
  fetchStudiosState: "NONE",
};

export const studioSlice = createSlice({
  initialState,
  name: "studioSlice",
  reducers: {
    createStudioAction: (state, { payload }) => {
      state.createStudioLoading = true;
      state.createStudioSuccess = false;
      state.createStudioError = false;
      state.createStudioErrorMessage = "";
    },
    createStudioSuccessAction: (state, { payload }) => {
      state.createStudioLoading = false;
      state.createStudioSuccess = true;
      state.studios = [...state.studios, payload];
    },
    createStudioErrorAction: (state, { payload }) => {
      state.createStudioLoading = false;
      state.createStudioError = true;
      state.createStudioErrorMessage = payload;
    },
    createStudioResetAction: (state) => {
      state.createStudioLoading = false;
      state.createStudioSuccess = false;
      state.createStudioError = false;
    },
    fetchStudiosAction: (state) => {
      state.fetchStudiosLoading = true;
      state.fetchStudiosSuccess = false;
      state.fetchStudiosError = false;
    },
    fetchStudiosSuccessAction: (state, { payload }) => {
      state.fetchStudiosLoading = false;
      state.fetchStudiosSuccess = true;
      state.studios = payload;
      state.fetchStudiosState = "FRESH";
    },
    fetchStudiosErrorAction: (state) => {
      state.studios = [];
      state.fetchStudiosLoading = false;
      state.fetchStudiosError = true;
    },
  },
});

function* createStudioSaga(action: {
  payload: CreateStudioData;
}): Generator<StrictEffect, void, Array<IStudio>> {
  try {
    const { name, primaryContent, ownerStudio, studioWebsite, defaultValues } =
      action.payload;
    const studioData: ICreateStudioRequestData = {
      name,
      primaryContent,
      studioWebsite,
      defaultValues,
      ...(ownerStudio?._id && { ownerStudioId: ownerStudio._id }),
    };
    const studio = yield call(StudiosAPI.createStudio, studioData);
    yield put(studioSlice.actions.createStudioSuccessAction(studio));
    yield put(tagSlice.actions.fetchTagsSetState("STALE"));
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response?.status === 409) {
      yield put(
        studioSlice.actions.createStudioErrorAction(
          "Failed to create studio because it already exists.",
        ),
      );
      return;
    }
    yield put(studioSlice.actions.createStudioErrorAction(""));
    captureSentryException("SagaError", error as Error, {
      Saga: "CreateStudioSaga",
    });
  }
}

function* fetchStudiosSaga(): Generator<
  StrictEffect,
  void,
  string | Array<IStudio>
> {
  try {
    const state = yield select(
      (state: IReactState) => state.studioState.fetchStudiosState,
    );
    if (state === "FRESH") {
      const studios = yield select(
        (state: IReactState) => state.studioState.studios,
      );
      yield put(studioSlice.actions.fetchStudiosSuccessAction(studios));
      return;
    }
    const studios = yield call(StudiosAPI.fetchStudios);
    yield put(studioSlice.actions.fetchStudiosSuccessAction(studios));
  } catch (error) {
    yield put(studioSlice.actions.fetchStudiosErrorAction());
    captureSentryException("SagaError", error as Error, {
      Saga: "FetchStudiosSaga",
    });
  }
}

export function* watcherSaga() {
  yield all([
    takeLatest(studioSlice.actions.createStudioAction, createStudioSaga),
    takeLatest(studioSlice.actions.fetchStudiosAction, fetchStudiosSaga),
  ]);
}

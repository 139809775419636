class ValidationUtils {
  public static readonly nameRegex = /^[a-zA-Z0-9]+([ -][a-zA-Z0-9]+)*$/;
  public static readonly urlRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,8}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/;

  public static readonly urlPrefixMap: {
    [key: string]: string;
  } = {
    K2S: "https://k2s.cc",
    FBOOM: "https://fboom.me",
    TEZFILES: "https://tezfiles.com",
    ANONFILES: "https://anonfiles.com",
    DEPOSITFILES: "https://depositfiles.com",
    FILEJOKER: "https://filejoker.net",
    FILEFACTORY: "https://www.filefactory.com",
    MEXASHARE: "https://mexa.sh/",
    MEDIAFIRE: "https://www.mediafire.com/",
    MEGA: "https://mega.nz",
    "DROP-DOWNLOAD": "https://drop.download",
  };

  public static isValidName(name: string): boolean {
    return ValidationUtils.nameRegex.test(name);
  }

  public static isValidUrl(url: string): boolean {
    return ValidationUtils.urlRegex.test(url);
  }

  public static isValidFilehostUrl(filehost: string, url: string): boolean {
    if (
      filehost === "RAPIDGATOR" &&
      !(
        url.startsWith("https://rapidgator.net") ||
        url.startsWith("https://rg.to")
      )
    ) {
      return false;
    }
    if (
      filehost === "DEPOSITFILES" &&
      !(
        url.startsWith("https://depositfiles.com") ||
        url.startsWith("https://dfiles.eu")
      )
    ) {
      return false;
    }
    const prefix = ValidationUtils.urlPrefixMap[filehost];
    if (prefix && !url.startsWith(prefix)) {
      return false;
    }
    return true;
  }
}

export default ValidationUtils;

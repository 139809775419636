import { ICreateStudioRequestData, IStudio } from "../types/Studio";
import API from "./API";

const api = new API();

export const createStudio = async (
  studioData: ICreateStudioRequestData,
): Promise<IStudio> => {
  return api
    .callApi({
      url: "/studios",
      method: "POST",
      data: studioData,
    })
    .then(({ data }) => data.data);
};

export const fetchStudios = async (): Promise<Array<IStudio>> => {
  return api
    .callApi({
      url: "/studios",
      method: "GET",
    })
    .then(({ data }) => data.data);
};

const StudiosAPI = {
  createStudio,
  fetchStudios,
};

export default StudiosAPI;

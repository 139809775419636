import { createSlice } from "@reduxjs/toolkit";
import {
  all,
  call,
  put,
  select,
  StrictEffect,
  takeLatest,
} from "redux-saga/effects";
import StarletsAPI from "../../api/StarletsAPI";
import { IReactState } from "../../types/ReactState";
import {
  CreateStarletData,
  ICreateStarletRequestData,
  IStarlet,
  IUpdateStarletRequestData,
  UpdateStarletData,
} from "../../types/Starlet";
import { tagSlice } from "../tagDuck/tagDuck";
import StarletState from "./types";
import { AxiosError } from "axios";
import { captureSentryException } from "../../config/Sentry";

const initialState: StarletState = {
  createStarletLoading: false,
  createStarletSuccess: false,
  createStarletError: false,
  createStarletErrorMessage: "",

  starlets: [],
  fetchStarletsLoading: false,
  fetchStarletsSuccess: false,
  fetchStarletsError: false,
  fetchStarletsState: "NONE",

  starlet: null,
  fetchStarletByIdLoading: false,
  fetchStarletByIdSuccess: false,
  fetchStarletByIdError: false,
  fetchStarletByIdErrorMessage: "",

  updateStarletByIdLoading: false,
  updateStarletByIdSuccess: false,
  updateStarletByIdError: false,
  updateStarletByIdErrorMessage: "",
};
export const starletSlice = createSlice({
  initialState,
  name: "starletSlice",
  reducers: {
    createStarletAction: (state, { payload }) => {
      state.createStarletLoading = true;
      state.createStarletSuccess = false;
      state.createStarletError = false;
      state.createStarletErrorMessage = "";
    },
    createStarletSuccessAction: (state, { payload }) => {
      state.createStarletLoading = false;
      state.createStarletSuccess = true;
      state.starlets = [...state.starlets, payload];
      state.starlet = payload;
    },
    createStarletErrorAction: (state, { payload }) => {
      state.createStarletLoading = false;
      state.createStarletError = true;
      state.createStarletErrorMessage = payload;
    },
    createStarletResetAction: (state) => {
      state.createStarletLoading = false;
      state.createStarletSuccess = false;
      state.createStarletError = false;
      state.createStarletErrorMessage = "";
      state.starlet = null;
    },
    fetchStarletsAction: (state) => {
      state.fetchStarletsLoading = true;
    },
    fetchStarletsSuccessAction: (state, { payload }) => {
      state.fetchStarletsLoading = false;
      state.fetchStarletsSuccess = true;
      state.starlets = payload;
      state.fetchStarletsState = "FRESH";
    },
    fetchStarletsErrorAction: (state) => {
      state.starlets = [];
      state.fetchStarletsLoading = false;
      state.fetchStarletsSuccess = false;
      state.fetchStarletsError = true;
    },
    fetchStarletsSetState: (state, { payload }) => {
      state.fetchStarletsState = payload;
    },
    fetchStarletByIdAction: (state, { payload }) => {
      state.starlet = null;
      state.fetchStarletByIdLoading = true;
      state.fetchStarletByIdSuccess = false;
      state.fetchStarletByIdError = false;
      state.fetchStarletByIdErrorMessage = "";
    },
    fetchStarletByIdSuccess: (state, { payload }) => {
      state.fetchStarletByIdLoading = false;
      state.fetchStarletByIdSuccess = true;
      state.starlet = payload;
    },
    fetchStarletByIdError: (state) => {
      state.fetchStarletByIdLoading = false;
      state.fetchStarletByIdError = true;
    },
    fetchStarletByIdResetAction: (state) => {
      state.fetchStarletByIdLoading = false;
      state.fetchStarletByIdSuccess = false;
      state.fetchStarletByIdError = false;
      state.fetchStarletByIdErrorMessage = "";
      state.starlet = null;
    },
    updateStarletByIdAction: (state, { payload }) => {
      state.updateStarletByIdLoading = true;
      state.updateStarletByIdSuccess = false;
      state.updateStarletByIdError = false;
      state.updateStarletByIdErrorMessage = "";
    },
    updateStarletByIdSuccessAction: (state, { payload }) => {
      state.updateStarletByIdLoading = false;
      state.updateStarletByIdSuccess = true;
      const index = state.starlets.findIndex(({ _id }) => _id === payload._id);
      state.starlets = [...state.starlets];
      state.starlets[index] = payload;
      state.starlet = payload;
    },
    updateStarletByIdErrorAction: (state, { payload }) => {
      state.updateStarletByIdLoading = false;
      state.updateStarletByIdError = true;
      state.updateStarletByIdErrorMessage = payload;
    },
    updateStarletByIdResetAction: (state) => {
      state.updateStarletByIdLoading = false;
      state.updateStarletByIdSuccess = false;
      state.updateStarletByIdError = false;
      state.updateStarletByIdErrorMessage = "";
      state.starlet = null;
    },
  },
});

function* createStarletSaga(action: {
  payload: CreateStarletData;
}): Generator<StrictEffect, void, Array<IStarlet>> {
  try {
    const { name, aka, nativeName, dob, tags } = action.payload;
    const starletData: ICreateStarletRequestData = {
      name,
      tagIds: tags.map(({ _id }) => _id),
      ...(aka && { aka }),
      ...(dob && { dob }),
      ...(nativeName && { nativeName }),
    };
    const starlet = yield call(StarletsAPI.createStarlet, starletData);
    yield put(starletSlice.actions.createStarletSuccessAction(starlet));
    yield put(tagSlice.actions.fetchTagsSetState("STALE"));
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response?.status === 409) {
      yield put(
        starletSlice.actions.createStarletErrorAction(
          "Failed to create starlet because it already exists.",
        ),
      );
      return;
    }
    yield put(starletSlice.actions.createStarletErrorAction(""));
    captureSentryException("SagaError", error as Error, {
      Saga: "CreateStarletSaga",
    });
  }
}

function* fetchStarletsSaga(): Generator<
  StrictEffect,
  void,
  string | Array<IStarlet>
> {
  try {
    const state = yield select(
      (state: IReactState) => state.starletState.fetchStarletsState,
    );
    if (state === "FRESH") {
      const starlets = yield select(
        (state: IReactState) => state.starletState.starlets,
      );
      yield put(starletSlice.actions.fetchStarletsSuccessAction(starlets));
      return;
    }
    const starlets = yield call(StarletsAPI.fetchStarlets);
    yield put(starletSlice.actions.fetchStarletsSuccessAction(starlets));
  } catch (error) {
    yield put(starletSlice.actions.fetchStarletsErrorAction());
    captureSentryException("SagaError", error as Error, {
      Saga: "FetchStarletsSaga",
    });
  }
}

function* fetchStarletByIdSaga(action: {
  payload: string;
}): Generator<StrictEffect, void, Array<IStarlet>> {
  try {
    const starlet = yield call(StarletsAPI.fetchStarletById, action.payload);
    yield put(starletSlice.actions.fetchStarletByIdSuccess(starlet));
  } catch (error) {
    yield put(starletSlice.actions.fetchStarletByIdError());
    captureSentryException("SagaError", error as Error, {
      Saga: "FetchStarletByIdSaga",
    });
  }
}

function* updateStarletByIdSaga(action: {
  payload: UpdateStarletData;
}): Generator<StrictEffect, void, Array<IStarlet>> {
  try {
    const { starletId, name, aka, dob, nativeName, tags } = action.payload;
    const starletData: IUpdateStarletRequestData = {
      name,
      aka,
      dob,
      nativeName,
      tagIds: tags.map(({ _id }) => _id),
    };
    const starlet = yield call(
      StarletsAPI.updateStarletById,
      starletId,
      starletData,
    );
    yield put(starletSlice.actions.updateStarletByIdSuccessAction(starlet));
    yield put(tagSlice.actions.fetchTagsSetState("STALE"));
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response?.status === 409) {
      yield put(
        starletSlice.actions.updateStarletByIdErrorAction(
          "Failed to update starlet because it already exists.",
        ),
      );
      return;
    }
    yield put(starletSlice.actions.updateStarletByIdErrorAction(""));
    captureSentryException("SagaError", error as Error, {
      Saga: "UpdateStarletByIdSaga",
    });
  }
}

export function* watcherSaga() {
  yield all([
    takeLatest(starletSlice.actions.createStarletAction, createStarletSaga),
    takeLatest(starletSlice.actions.fetchStarletsAction, fetchStarletsSaga),
    takeLatest(
      starletSlice.actions.fetchStarletByIdAction,
      fetchStarletByIdSaga,
    ),
    takeLatest(
      starletSlice.actions.updateStarletByIdAction,
      updateStarletByIdSaga,
    ),
  ]);
}

import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Upload,
} from "antd";
import * as DOMPurify from "dompurify";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ImagesAPI from "../api/ImagesAPI";
import { BEFORE_UNLOAD_MESSAGE } from "../consts/consts";
import {
  CreatePostData,
  IDraftPost,
  IFileHost,
  IPost,
  PostFormValues,
} from "../types/Post";
import IReactState from "../types/ReactState";
import { IStudio } from "../types/Studio";
import { IUser } from "../types/User";
import DateUtils from "../utils/DateUtils";
import Utils from "../utils/Utils";
import ValidationUtils from "../utils/ValidationUtils";
import FileHosts from "./FileHosts";
import PostPreview from "./PostPreview";
import ResetSwitch from "./ResetSwitch";
import CategorySelector from "./selectors/CategorySelector";
import FileSizeSelector from "./selectors/FileSizeSelector";
import RemarkSelector from "./selectors/RemarkSelector";
import StarletsSelector from "./selectors/StarletsSelector";
import StudioSelector from "./selectors/StudioSelector";
import TagsSelector from "./selectors/TagsSelector";

const { Dragger } = Upload;

interface IProps {
  post?: IPost;
  draftPost?: IDraftPost;
  isEdit: boolean;
  loading: boolean;
  isSuccess: boolean;
  onSubmit: (postData: any) => void;
}
const PostFrom = (props: IProps) => {
  const navigate = useNavigate();
  const imagesRef = useRef<HTMLDivElement | null>(null);
  const [form] = Form.useForm();
  const [resetOnSuccess, toggleReset] = useState(true);
  const [isPreviewVisible, togglePreview] = useState(false);
  const { post, draftPost, isEdit, loading, isSuccess, onSubmit } = props;
  const initialValues = post
    ? Utils.postFormInitialValues(post)
    : {
        releaseDate: DateUtils.getCurrentDate(),
      };

  useEffect(() => {
    const beforeUnloadCallback = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = BEFORE_UNLOAD_MESSAGE;
      return BEFORE_UNLOAD_MESSAGE;
    };

    window.addEventListener("beforeunload", beforeUnloadCallback);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadCallback);
    };
  }, []);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }
    if (resetOnSuccess) {
      form.resetFields();
    } else {
      form.resetFields(["description", "downloads", "content", "zipSize"]);
    }
  }, [form, resetOnSuccess, isSuccess]);

  useEffect(() => {
    if (draftPost) {
      const { imageWidth, imageHeight, imagesCount, fileSize, downloads } =
        draftPost;
      form.setFieldValue("imageWidth", imageWidth);
      form.setFieldValue("imageHeight", imageHeight);
      form.setFieldValue("imagesCount", imagesCount);
      form.setFieldValue("zipSize", {
        fileSize: (fileSize / (fileSize < 1024 ? 1 : 1024)).toFixed(2),
        sizeIn: fileSize < 1024 ? "MB" : "GB",
      });
      form.setFieldValue("downloads", downloads);
    }
  }, [draftPost, form]);

  const uploadImage = async (options: any) => {
    const { onSuccess, onError, file } = options;
    const image = new Image();
    image.src = window.URL.createObjectURL(file);
    image.onload = async () => {
      if (image.width < 600 || image.height < 600) {
        notification.error({
          message: "Image Size Error",
          description: "Image is too small for cover image.",
        });
        return onError("Failed to upload image.");
      }
      const url = await ImagesAPI.uploadImage({
        image: file,
      });

      if (!url) {
        notification.error({
          message: "Image Upload Error",
          description: "Failed to update image, please try again.",
        });
        return onError("Failed to upload image.");
      }
      form.setFieldValue("cover", url);
      onSuccess("OK");
    };
  };

  const postStudioSelect = (studio: IStudio) => {
    const { imageWidth, imageHeight, coverImagesCount, collectionName } =
      Utils.postStudioSelect(studio);

    form.setFieldValue("coverImagesCount", coverImagesCount);

    if (isEdit) {
      return;
    }
    if (imageWidth) {
      form.setFieldValue("imageWidth", imageWidth);
    }
    if (imageHeight) {
      form.setFieldValue("imageHeight", imageHeight);
    }
    form.setFieldValue("collectionName", collectionName);
    if (["Gals"].includes(studio.name)) {
      form.setFieldValue("title", "— Set 00");
    }
    if (["OnlyFans"].includes(studio.name)) {
      form.setFieldValue("title", "Set 00");
    }
    if (["XiuRen"].includes(studio.name)) {
      form.setFieldValue("title", "No.000");
    }
    if (["XiaoYu", "MiiTao", "MFStar", "YouMi"].includes(studio.name)) {
      form.setFieldValue("title", "Vol.000");
    }
    if (["BimilStory"].includes(studio.name)) {
      form.setFieldValue("title", "Vol.00 —");
    }
  };

  const onFinish = (formData: PostFormValues) => {
    const {
      zipSize,
      downloads,
      imagesCount,
      imageHeight,
      imageWidth,
      coverImagesCount,
      description,
      ...postData
    } = formData;
    const content = imagesRef.current?.innerHTML || "";
    const imageTagsCount = Math.ceil(
      (imagesRef.current?.childNodes.length || 0) / 2,
    );
    const totalImages =
      Math.ceil(imagesCount) + parseInt(coverImagesCount || "0");
    if (totalImages !== imageTagsCount) {
      notification.error({
        message: "Image Count Error",
        description: `Images links count and images count did not match. Image Count: ${imagesCount} Links Count ${imageTagsCount}`,
      });
      return;
    }

    if (Math.ceil(imageWidth) < Math.ceil(imageHeight)) {
      notification.error({
        message: "Images Resolution Error",
        description: "Images width should be greater than height.",
      });
      return;
    }

    let fileSize = zipSize.fileSize;
    if (zipSize.fileSize && zipSize.sizeIn === "GB") {
      fileSize = fileSize * 1024;
    }
    const downloadsArray: Array<IFileHost> = [];
    if (downloads) {
      Object.entries(downloads).forEach(([filehost, url]) => {
        if (url) {
          downloadsArray.push({ filehost, url });
        }
      });
    }
    if (!downloadsArray.length) {
      notification.error({
        message: "Download Links Error",
        description: "Provide at least one download link for any filehost.",
      });
      return;
    }
    const sanitizedContent = DOMPurify.sanitize(content, {
      USE_PROFILES: { html: true },
    });

    let sanitizedDescription = "";
    if (description) {
      sanitizedDescription = DOMPurify.sanitize(description, {
        USE_PROFILES: { html: true },
      });
    }

    onSubmit({
      ...postData,
      imageHeight,
      imageWidth,
      imagesCount,
      content: sanitizedContent,
      description: sanitizedDescription,
      downloads: downloadsArray,
      zipSize: fileSize,
    });
  };

  const onCancel = () => {
    navigate(-1);
  };

  const user = useSelector(
    (state: IReactState) => state.authState.user,
  ) as IUser;

  const downloads = Form.useWatch("downloads", form);

  useEffect(() => {
    Object.entries(downloads || {}).forEach(([filehost, url]) => {
      const k2sUrl = `${url}`;
      if (
        filehost === "K2S" &&
        !ValidationUtils.isValidFilehostUrl(filehost, k2sUrl)
      ) {
        form.resetFields([["downloads", "FBOOM"]]);
        form.resetFields([["downloads", "TEZFILES"]]);
      }
      if (
        filehost === "K2S" &&
        ValidationUtils.isValidFilehostUrl(filehost, k2sUrl)
      ) {
        const fboomUrl = k2sUrl.replace("https://k2s.cc", "https://fboom.me");
        const tezfilesUrl = k2sUrl.replace(
          "https://k2s.cc",
          "https://tezfiles.com",
        );
        if (!downloads["FBOOM"]) {
          form.setFieldValue(["downloads", "FBOOM"], fboomUrl);
        }
        if (!downloads["TEZFILES"]) {
          form.setFieldValue(["downloads", "TEZFILES"], tezfilesUrl);
        }
      }
    });
  }, [form, downloads]);

  const downloadsArray: Array<IFileHost> = [];
  Object.entries(Form.useWatch("downloads", form) || {}).forEach(
    ([filehost, url]) => {
      if (url) {
        downloadsArray.push({ filehost, url: url as string });
      }
    },
  );

  const postData: CreatePostData = {
    title: Form.useWatch("title", form),
    description: Form.useWatch("description", form),
    cover: Form.useWatch("cover", form),
    starlets: Form.useWatch("starlets", form),
    studio: Form.useWatch("studio", form),
    releaseDate: Form.useWatch("releaseDate", form),
    imagesCount: Form.useWatch("imagesCount", form),
    imageHeight: Form.useWatch("imageHeight", form),
    imageWidth: Form.useWatch("imageWidth", form),
    content: Form.useWatch("content", form),
    downloads: downloadsArray,
    tags: Form.useWatch("tags", form),
    zipSize: 0,
    remark: Form.useWatch("remark", form),
    createdBy: {
      _id: user._id,
      avatar: user.avatar,
      username: user.username,
    },
    collectionName: Form.useWatch("collectionName", form),
  };

  const zipSize = Form.useWatch("zipSize", form);
  if (zipSize && zipSize.fileSize) {
    if (zipSize.sizeIn === "GB") {
      postData.zipSize = zipSize.fileSize * 1024;
    } else {
      postData.zipSize = zipSize.fileSize;
    }
  }

  if (imagesRef.current) {
    const imageTags = imagesRef.current.getElementsByTagName("a");
    for (let index = 0; index < imageTags.length; index++) {
      imageTags.item(index)?.setAttribute("target", "_blank");
      imageTags.item(index)?.setAttribute("rel", "nofollow");
    }
  }
  return (
    <Row justify="center">
      <Col span={24}>
        <div className={!isPreviewVisible ? "hidden" : "block"}>
          <PostPreview postData={postData} imagesRef={imagesRef} />
        </div>
      </Col>
      <Col span={20}>
        <Form
          layout="vertical"
          autoComplete="off"
          form={form}
          initialValues={initialValues}
          onFinish={onFinish}
          requiredMark={false}
        >
          <Row justify="center">
            <Col>
              <Form.Item hidden name="cover" required={!isEdit}>
                <Input type="hidden" />
              </Form.Item>
              <Form.Item
                name="image"
                rules={[
                  {
                    required: !isEdit,
                    message: "Upload post cover image.",
                  },
                ]}
              >
                <Dragger accept="image/jpeg" customRequest={uploadImage}>
                  <div className="px-4">
                    <p className="ant-upload-drag-icon"></p>
                    <p className="ant-upload-text">Imageset Cover Image</p>
                    <p className="ant-upload-hint">
                      Image must have 3:4 aspect ratio and at least 800x1080
                      resolution
                    </p>
                    <p className="text-sm text-zinc-800">
                      Click or drag image file to this area to upload
                    </p>
                  </div>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label="Studio"
                name="studio"
                rules={[
                  {
                    required: true,
                    message: "Select publisher studio.",
                  },
                ]}
              >
                <StudioSelector
                  onChange={(studio) => {
                    form.setFieldValue("studio", studio);
                    postStudioSelect(studio);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Collection Category"
                name="collectionName"
                rules={[
                  {
                    required: true,
                    message: "Select appropriate category.",
                  },
                ]}
              >
                <CategorySelector />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Starlets"
            name="starlets"
            rules={[
              {
                required: true,
                message: "Select at least one starlet.",
              },
            ]}
          >
            <StarletsSelector
              onChange={(starlets) => {
                form.setFieldValue("starlets", starlets);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Title"
            name="title"
            rules={[
              { required: true, message: "Photoset title is required." },
              {
                validateTrigger: "submit",
                validator: (rule, value) => {
                  if (value && value.length >= 3) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "Short title, at least 3 characters are required.",
                  );
                },
              },
            ]}
          >
            <Input size="large" placeholder="Photoset title" />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea size="large" placeholder="Photoset description" />
          </Form.Item>
          <Form.Item
            label="Images Links"
            name="content"
            rules={[{ required: true, message: "Images links are required." }]}
          >
            <Input.TextArea rows={10} />
          </Form.Item>
          <Row gutter={10}>
            <Col span={8}>
              <Form.Item
                label="Release Date"
                name="releaseDate"
                rules={[
                  {
                    validateTrigger: "submit",
                    validator: (rule, value) => {
                      if (!value || DateUtils.isValid(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        `Invalid date, should be in ${DateUtils.getCurrentDate()} format.`,
                      );
                    },
                  },
                ]}
              >
                <Input size="large" placeholder={DateUtils.getCurrentDate()} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Images Width"
                name="imageWidth"
                rules={[
                  {
                    required: true,
                    message: "Images width is required.",
                  },
                ]}
              >
                <InputNumber
                  size="large"
                  min={1}
                  step={1}
                  addonAfter="Pixels"
                  placeholder="Image width ie. 3000"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Images Height"
                name="imageHeight"
                rules={[
                  {
                    required: true,
                    message: "Image height is required.",
                  },
                ]}
              >
                <InputNumber
                  size="large"
                  min={1}
                  step={1}
                  addonAfter="Pixels"
                  placeholder="Image height ie. 2000"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={8}>
              <Form.Item
                label="Images Count"
                name="imagesCount"
                rules={[
                  {
                    required: true,
                    message: "Images count is required.",
                  },
                ]}
              >
                <InputNumber
                  size="large"
                  min={1}
                  step={1}
                  addonAfter="Images"
                  placeholder="Number of images"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Cover Images Count" name="coverImagesCount">
                <InputNumber
                  size="large"
                  min={0}
                  step={1}
                  addonAfter="Images"
                  placeholder="Number of cover images"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Zip File Size">
                <Input.Group compact>
                  <Form.Item
                    name={["zipSize", "fileSize"]}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: "Zip file size is required.",
                      },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      min={0}
                      step={0.01}
                      style={{ width: "70%" }}
                      placeholder="Input file size"
                    />
                  </Form.Item>
                  <Form.Item name={["zipSize", "sizeIn"]} noStyle>
                    <FileSizeSelector style={{ width: "30%" }} />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Tags"
            name="tags"
            rules={[{ required: true, message: "Select tags." }]}
          >
            <TagsSelector
              onChange={(tags) => {
                form.setFieldValue("tags", tags);
              }}
              type="ALL"
            />
          </Form.Item>
          <FileHosts />
          <Form.Item label="Addition Remark" name="remark">
            <RemarkSelector
              onChange={(remark) => {
                form.setFieldValue("remark", remark);
              }}
            />
          </Form.Item>
          {toggleReset && <ResetSwitch setReset={toggleReset} />}
          <Row justify="center" gutter={10}>
            {isEdit && (
              <Col span={8}>
                <Form.Item>
                  <Button block size="large" onClick={onCancel}>
                    Cancel
                  </Button>
                </Form.Item>
              </Col>
            )}
            <Col span={8}>
              <Form.Item>
                <Button
                  block
                  size="large"
                  onClick={() => togglePreview(!isPreviewVisible)}
                >
                  {isPreviewVisible ? "Hide Preview" : "Show Preview"}
                </Button>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Button
                  block
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {!isEdit && <>{loading ? "Creating Post" : "Create Post"}</>}
                  {isEdit && <>{loading ? "Updating Post" : "Update Post"}</>}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
      <div className=""></div>
    </Row>
  );
};

export default PostFrom;

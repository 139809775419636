import React from "react";
import { Select, SelectProps } from "antd";
import { REMARKS } from "../../consts/consts";

const remarkOptions = Object.entries(REMARKS).map(([value, label]) => ({
  value,
  label,
}));

const RemarkSelector = (props: SelectProps) => {
  return (
    <Select
      allowClear
      size="large"
      placeholder="Select remark..."
      options={remarkOptions}
      {...props}
    />
  );
};

export default RemarkSelector;

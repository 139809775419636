import React, { useEffect, useMemo } from "react";
import { Button, Form, Input, Typography, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import IReactState from "../types/ReactState";
import { authSlice } from "../ducks/authDuck/authDuck";
import { LoginData } from "../ducks/authDuck/types";
import Utils from "../utils/Utils";
import { BACKGROUNDS_IMAGES } from "../consts/consts";
import IconHeart from "../assets/icons/IconHeart";

const { Title } = Typography;

const Login = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authState = useSelector((state: IReactState) => state.authState);
  const { loginLoading, loginError, isAuthenticated } = authState;

  const backgroundImage = useMemo(() => {
    return Utils.shuffleArray(BACKGROUNDS_IMAGES.loginPage).at(0);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard", {
        replace: true,
      });
    }
  }, [navigate, isAuthenticated]);

  const onFinish = (loginData: LoginData) => {
    logIn(loginData);
  };

  const logIn = (loginData: LoginData) => {
    dispatch(authSlice.actions.userLoginAction(loginData));
  };

  return (
    <div className="flex flex-col justify-between h-screen">
      <header className="px-6 py-4 z-20 drop-shadow-lg">
        <span className="text-rose-600 text-4xl font-extrabold fw-extrabold">
          RedPics
        </span>
      </header>
      <main>
        <div className="absolute flex top-0 bottom-0 left-0 right-0 z-10">
          <img
            className="w-full object-cover object-center bg-black"
            src={backgroundImage}
            alt=""
          />
        </div>
        <div className="relative z-20 px-4">
          <Row justify="end">
            <Col
              xs={24}
              md={8}
              className="px-6 py-4 rounded-lg backdrop-blur-sm bg-black/20"
            >
              <div className="text-center pb-4 drop-shadow-lg">
                <div className="py-2">
                  <Title level={3} style={{ margin: 0 }}>
                    RedPics Blogs Collaboration
                  </Title>
                </div>
                <div>
                  <Title level={5} style={{ margin: 0 }}>
                    Hi there, please login to continue...
                  </Title>
                </div>
              </div>
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
                requiredMark={false}
              >
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[{ required: true, message: "Username is required." }]}
                >
                  <Input size="large" placeholder="Username" />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: "Password is required." }]}
                >
                  <Input.Password size="large" placeholder="••••••••••" />
                </Form.Item>

                {/* <Form.Item>
                      <a className="login-form-forgot" href="/">
                        Forgot password
                      </a>
                    </Form.Item> */}
                <div className="mb-4 text-rose-600">
                  {loginError && "Invalid username or password."}
                </div>
                <Form.Item>
                  <Button
                    block
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={loginLoading}
                  >
                    {loginLoading ? "Logging In" : "Login"}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </main>
      <footer className="px-6 py-5 text-center z-20">
        <Title level={4}>
          <span className="text-rose-600 drop-shadow-lg">RedPics</span>
        </Title>
        <Title level={5}>
          <div className="flex items-center justify-center">
            <span>Made With</span>
            <span className="h-5 px-1 inline-block text-rose-600">
              <IconHeart />
            </span>
            <span>For Everyone</span>
          </div>
        </Title>
      </footer>
    </div>
  );
};

export default Login;

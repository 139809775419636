import { createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
  all,
  call,
  put,
  StrictEffect,
  takeLatest,
  takeEvery,
} from "redux-saga/effects";
import PostAPI from "../../api/PostsAPI";
import { captureSentryException } from "../../config/Sentry";
import {
  CreatePostData,
  GetPostsData,
  ICreatePostRequestData,
  IGetPostsResponseData,
  IPost,
  IUpdatePostRequestData,
  UpdatePostData,
  UpdatePostStatusData,
} from "../../types/Post";
import { starletSlice } from "../starletDuck/starletDuck";
import { tagSlice } from "../tagDuck/tagDuck";
import PostState from "./types";

const initialState: PostState = {
  createdPost: null,
  createPostLoading: false,
  createPostSuccess: false,
  createPostError: false,
  createPostErrorMessage: "",

  posts: [],
  totalPosts: 0,
  fetchPostsLoading: false,
  fetchPostsSuccess: false,
  fetchPostsError: false,

  post: null,
  fetchPostByIdLoading: false,
  fetchPostByIdSuccess: false,
  fetchPostByIdError: false,

  updatePostByIdLoading: false,
  updatePostByIdSuccess: false,
  updatePostByIdError: false,
  updatePostByIdErrorMessage: "",

  postRows: {},
};

export const postSlice = createSlice({
  initialState,
  name: "postSlice",
  reducers: {
    createPostAction: (
      state,
      action: {
        payload: CreatePostData;
      },
    ) => {
      state.createdPost = null;
      state.createPostLoading = true;
      state.createPostSuccess = false;
      state.createPostError = false;
      state.createPostErrorMessage = "";
    },
    createPostSuccessAction: (state, { payload }) => {
      state.createPostLoading = false;
      state.createPostSuccess = true;
      state.createdPost = payload;
      state.posts = [payload, ...state.posts];
    },
    createPostErrorAction: (state, { payload }) => {
      state.createPostLoading = false;
      state.createPostError = true;
    },
    createPostResetAction: (state) => {
      state.createdPost = null;
      state.createPostLoading = false;
      state.createPostSuccess = false;
      state.createPostError = false;
      state.createPostErrorMessage = "";
    },
    fetchPostsAction: (state, { payload }) => {
      state.posts = [];
      state.totalPosts = 0;
      state.fetchPostsLoading = true;
      state.fetchPostsSuccess = false;
      state.fetchPostsError = false;
    },
    fetchPostsSuccess: (state, { payload }) => {
      state.fetchPostsLoading = false;
      state.fetchPostsSuccess = true;
      state.posts = payload.posts;
      state.totalPosts = payload.totalPosts;
    },
    fetchPostsError: (state) => {
      state.fetchPostsLoading = false;
      state.fetchPostsError = true;
    },
    fetchPostByIdAction: (state, { payload }) => {
      state.post = null;
      state.fetchPostByIdLoading = true;
      state.fetchPostByIdSuccess = false;
      state.fetchPostByIdError = false;
    },
    fetchPostByIdSuccess: (state, { payload }) => {
      state.fetchPostByIdLoading = false;
      state.fetchPostByIdSuccess = true;
      state.post = payload;
    },
    fetchPostByIdError: (state) => {
      state.fetchPostByIdLoading = false;
      state.fetchPostByIdError = true;
    },
    updatePostByIdAction: (state, { payload }) => {
      state.updatePostByIdLoading = true;
      state.updatePostByIdSuccess = false;
      state.updatePostByIdError = false;
      state.updatePostByIdErrorMessage = "";
    },
    updatePostByIdSuccessAction: (state, { payload }) => {
      state.updatePostByIdLoading = false;
      state.updatePostByIdSuccess = true;
      state.post = payload;
    },
    updatePostByIdErrorAction: (state, { payload }) => {
      state.updatePostByIdLoading = false;
      state.updatePostByIdError = true;
      state.updatePostByIdErrorMessage = payload;
    },
    updatePostByIdResetAction: (state) => {
      state.updatePostByIdLoading = false;
      state.updatePostByIdSuccess = false;
      state.updatePostByIdError = false;
      state.updatePostByIdErrorMessage = "";
    },
    updatePostStatusByIdAction: (state, { payload }) => {
      const { postId } = payload;
      state.postRows[postId] = {
        updatePostStatusByIdLoading: true,
        updatePostStatusByIdSuccess: false,
        updatePostStatusByIdError: false,
        updatePostStatusByIdErrorMessage: "",
      };
    },
    updatePostStatusByIdSuccessAction: (state, { payload }) => {
      const { _id: postId } = payload;
      state.postRows[postId] = {
        updatePostStatusByIdLoading: false,
        updatePostStatusByIdSuccess: true,
        updatePostStatusByIdError: false,
        updatePostStatusByIdErrorMessage: "",
      };
      const index = state.posts.findIndex(({ _id }) => _id === postId);
      state.posts = [...state.posts];
      state.posts[index] = payload;
    },
    updatePostStatusByIdErrorAction: (state, { payload }) => {
      const { postId, message } = payload;
      state.postRows[postId] = {
        updatePostStatusByIdLoading: false,
        updatePostStatusByIdSuccess: false,
        updatePostStatusByIdError: true,
        updatePostStatusByIdErrorMessage: message,
      };
    },
    updatePostStatusByIdResetAction: (state) => {
      state.postRows = {};
    },
  },
});

function* createPostSaga(action: {
  payload: CreatePostData;
}): Generator<StrictEffect, void, IPost> {
  try {
    const {
      title,
      description,
      cover,
      studio,
      starlets,
      releaseDate,
      imagesCount,
      imageHeight,
      imageWidth,
      content,
      tags,
      zipSize,
      remark,
      downloads,
      collectionName,
    } = action.payload;
    const postData: ICreatePostRequestData = {
      title: title.replace(/\s\s+/g, " "),
      description: description?.replace(/\s\s+/g, " "),
      cover,
      imagesCount,
      imageHeight,
      imageWidth,
      content,
      zipSize,
      remark,
      downloads,
      collectionName,
      studioId: studio._id,
      tagIds: tags.map(({ _id }) => _id),
      starletIds: starlets.map(({ _id }) => _id),
    };
    if (releaseDate) {
      Object.assign(postData, {
        releaseDate,
      });
    }
    const post = yield call(PostAPI.createPost, postData);
    yield put(postSlice.actions.createPostSuccessAction(post));
    yield put(starletSlice.actions.fetchStarletsSetState("STALE"));
    yield put(tagSlice.actions.fetchTagsSetState("STALE"));
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response?.status === 409) {
      yield put(
        postSlice.actions.createPostErrorAction(
          "Failed to create post because it already exists.",
        ),
      );
      return;
    }
    yield put(postSlice.actions.createPostErrorAction(""));
    captureSentryException("SagaError", error as Error, {
      Saga: "CreatePostSaga",
    });
  }
}

function* fetchPostsSaga(action: {
  payload: GetPostsData;
}): Generator<StrictEffect, void, IGetPostsResponseData> {
  try {
    const {
      title,
      collectionName,
      tags,
      starlets,
      studio,
      currentPage,
      pageSize,
      sortBy,
    } = action.payload;
    const requestData = {
      currentPage,
      pageSize,
      filters: {
        ...(title && { title }),
        ...(collectionName && { collectionName }),
        ...(studio && { studioIds: [studio._id] }),
        ...(starlets && { starletIds: starlets.map(({ _id }) => _id) }),
        ...(tags && { tagIds: tags.map(({ _id }) => _id) }),
      },
      sortBy,
    };
    const { posts, total: totalPosts } = yield call(
      PostAPI.fetchPosts,
      requestData,
    );
    yield put(
      postSlice.actions.fetchPostsSuccess({
        posts,
        totalPosts,
      }),
    );
  } catch (error) {
    yield put(postSlice.actions.fetchPostsError());
    captureSentryException("SagaError", error as Error, {
      Saga: "FetchPostsSaga",
    });
  }
}

function* fetchPostByIdSaga(action: {
  payload: string;
}): Generator<StrictEffect, void, IPost> {
  try {
    const post = yield call(PostAPI.fetchPostById, action.payload);
    if (post) {
      post.tags = post.tags.filter(({ type }) =>
        ["SCENE", "OTHER"].includes(type),
      );
    }
    yield put(postSlice.actions.fetchPostByIdSuccess(post));
  } catch (error) {
    yield put(postSlice.actions.fetchPostByIdError());
    captureSentryException("SagaError", error as Error, {
      Saga: "FetchPostByIdSaga",
    });
  }
}

function* updatePostByIdSaga(action: {
  payload: UpdatePostData;
}): Generator<StrictEffect, void, IPost> {
  try {
    const {
      postId,
      title,
      description,
      cover,
      studio,
      starlets,
      releaseDate,
      imagesCount,
      imageHeight,
      imageWidth,
      content,
      tags,
      zipSize,
      remark,
      downloads,
      collectionName,
    } = action.payload;
    const postData: IUpdatePostRequestData = {
      ...(title && { title: title.replace(/\s\s+/g, " ") }),
      ...(cover && { cover }),
      ...(imagesCount && { imagesCount }),
      ...(imageHeight && { imageHeight }),
      ...(imageWidth && { imageWidth }),
      ...(content && { content }),
      ...(zipSize && { zipSize }),
      ...(remark && { remark }),
      ...(downloads && { downloads }),
      ...(collectionName && { collectionName }),
      ...(studio && { studioId: studio._id }),
      ...(tags && { tagIds: tags.map(({ _id }) => _id) }),
      ...(starlets && { starletIds: starlets.map(({ _id }) => _id) }),
      description: description?.replace(/\s\s+/g, " "),
    };

    if (Object.keys(action.payload).includes("releaseDate")) {
      Object.assign(postData, {
        releaseDate: releaseDate || null,
      });
    }
    const post = yield call(PostAPI.updatePostById, postId, postData);
    post.tags = post.tags.filter(({ type }) =>
      ["SCENE", "OTHER"].includes(type),
    );
    yield put(postSlice.actions.updatePostByIdSuccessAction(post));
    yield put(starletSlice.actions.fetchStarletsSetState("STALE"));
    yield put(tagSlice.actions.fetchTagsSetState("STALE"));
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response?.status === 409) {
      yield put(
        postSlice.actions.updatePostByIdErrorAction(
          "Failed to update post because it already exists.",
        ),
      );
      return;
    }
    yield put(postSlice.actions.updatePostByIdErrorAction(""));
    captureSentryException("SagaError", error as Error, {
      Saga: "updatePostByIdSaga",
    });
  }
}

function* updatePostStatusByIdSaga(action: {
  payload: UpdatePostStatusData;
}): Generator<StrictEffect, void, Array<IPost>> {
  const { postId, status } = action.payload;
  try {
    const post = yield call(PostAPI.updatePostStatusById, postId, status);
    yield put(postSlice.actions.updatePostStatusByIdSuccessAction(post));
  } catch (error) {
    yield put(
      postSlice.actions.updatePostStatusByIdErrorAction({
        postId,
        message: "",
      }),
    );
    captureSentryException("SagaError", error as Error, {
      Saga: "UpdatePostStatusByIdSaga",
    });
  }
}

export function* watcherSaga() {
  yield all([takeLatest(postSlice.actions.createPostAction, createPostSaga)]);
  yield all([takeLatest(postSlice.actions.fetchPostsAction, fetchPostsSaga)]);
  yield all([
    takeLatest(postSlice.actions.fetchPostByIdAction, fetchPostByIdSaga),
  ]);
  yield all([
    takeEvery(
      postSlice.actions.updatePostStatusByIdAction,
      updatePostStatusByIdSaga,
    ),
  ]);
  yield all([
    takeLatest(postSlice.actions.updatePostByIdAction, updatePostByIdSaga),
  ]);
}

import { authSlice } from "../ducks/authDuck/authDuck";
import { dashboardSlice } from "../ducks/dashboardDuck/dashboardDuck";
import { postSlice } from "../ducks/postDuck/postDuck";
import { starletSlice } from "../ducks/starletDuck/starletDuck";
import { tagSlice } from "../ducks/tagDuck/tagDuck";
import { userSlice } from "../ducks/userDuck/userDuck";
import { studioSlice } from "../ducks/studioDuck/studioDuck";
import { appSlice } from "../ducks/appDuck/appDuck";
import { reportSlice } from "../ducks/reportDuck/reportDuck";

const rootReducer = {
  appState: appSlice.reducer,
  authState: authSlice.reducer,
  userState: userSlice.reducer,
  starletState: starletSlice.reducer,
  studioState: studioSlice.reducer,
  postState: postSlice.reducer,
  tagState: tagSlice.reducer,
  dashboardState: dashboardSlice.reducer,
  reportState: reportSlice.reducer,
};

export default rootReducer;

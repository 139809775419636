import { Table, Pagination, Col, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import IconEdit from "../../assets/icons/IconEdit";
import PageContainer from "../../components/PageContainer";
import SkeletonTable, {
  SkeletonTableColumnsType,
} from "../../components/SkeletonTable";
import { CATEGORIES } from "../../consts/consts";
import { studioSlice } from "../../ducks/studioDuck/studioDuck";
import IReactState from "../../types/ReactState";
import DateUtils from "../../utils/DateUtils";

const Studios = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    pageSize: 50,
    currentPage: 1,
  });

  useEffect(() => {
    dispatch(studioSlice.actions.fetchStudiosAction());
  }, [dispatch]);

  const studioState = useSelector((state: IReactState) => state.studioState);

  const { studios, fetchStudiosLoading } = studioState;

  const total = studios.length;
  const { currentPage, pageSize } = pagination;
  const start = pageSize * (currentPage - 1);
  let end = pageSize * (currentPage - 1) + pageSize;
  end = Math.min(end, total);

  const studiosSorted = useMemo(() => {
    return [...studios]
      .sort((a, b) => a.name.localeCompare(b.name))
      .slice(start, end);
  }, [studios, start, end]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: <span className="whitespace-nowrap">Owner Studio</span>,
      dataIndex: "owner",
      key: "owner",
    },
    {
      title: <span className="whitespace-nowrap">Primary Content</span>,
      dataIndex: "primaryContent",
      key: "primaryContent",
    },
    {
      title: "Posts",
      dataIndex: "postsCount",
      key: "postsCount",
    },
    {
      title: "Images",
      dataIndex: "imagesCount",
      key: "imagesCount",
    },
    {
      title: <span className="whitespace-nowrap">Created By</span>,
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: <span className="whitespace-nowrap">Created On</span>,
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      render: () => (
        <Row>
          <Col>
            <Link to="/">
              <div className="p-2 h-4">
                <IconEdit />
              </div>
            </Link>
          </Col>
        </Row>
      ),
    },
  ];
  const dataSource = studiosSorted.map((studio) => {
    const {
      _id: studioId,
      name,
      ownerStudio,
      primaryContent,
      postsCount,
      imagesCount,
      createdBy,
      createdAt,
    } = studio;
    return {
      key: studioId,
      name,
      postsCount,
      imagesCount,
      primaryContent: CATEGORIES[primaryContent],
      owner: ownerStudio?.name || "NA",
      createdBy: createdBy.username,
      createdAt: DateUtils.getFormattedDate(createdAt),
    };
  });

  const onClickCreateStudios = () => {
    navigate("/studios/create");
  };

  const actions = [
    {
      name: "Create Studio",
      onClick: onClickCreateStudios,
    },
  ];

  return (
    <PageContainer title="Studios" actions={actions}>
      <div>
        <div>
          <SkeletonTable
            active={fetchStudiosLoading}
            loading={fetchStudiosLoading}
            columns={columns as SkeletonTableColumnsType[]}
          >
            <Table
              size="middle"
              columns={columns}
              dataSource={dataSource}
              pagination={false}
            />
          </SkeletonTable>
        </div>
        <div>
          <div className="flex justify-between px-4 pt-4">
            <div>
              <span className="text-white">
                Showing {start}-{end} of {total}
              </span>
            </div>
            <div>
              <Pagination
                defaultCurrent={1}
                current={pagination.currentPage}
                total={total}
                pageSize={pagination.pageSize}
                onChange={(page, pageSize) => {
                  setPagination({
                    pageSize,
                    currentPage: page,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default Studios;

//https://i6.vipr.im/i/06241/skcp78ypu9ph.jpg/005.jpg

import { Typography, theme } from "antd";
import React from "react";
import { REMARKS } from "../consts/consts";
import DateUtils from "../utils/DateUtils";

interface IProps {
  coverImageUrl: string;
  title: string;
  releaseDate: string;
  remark?: string;
  createdBy: {
    _id: string;
    username: string;
    avatar: string;
  };
  createdAt: Date;
}

const PostCard = (props: IProps) => {
  const {
    token: { colorBgElevated },
  } = theme.useToken();

  const { coverImageUrl, title, createdBy, createdAt } = props;
  let { remark } = props;
  if (remark) {
    remark = REMARKS[remark];
  }

  return (
    <div
      style={{
        background: colorBgElevated,
      }}
      className="w-full h-full rounded-lg overflow-hidden shadow-lg"
    >
      <div className="flex flex-col h-full">
        <div>
          <img
            className="w-full aspect-[3/4] h-object-center object-cover"
            src={
              coverImageUrl ||
              "https://images.pexels.com/photos/5984612/pexels-photo-5984612.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            }
            alt={title}
          />
        </div>
        <div className="p-4 flex-auto flex flex-col justify-between">
          <div>
            <Typography.Title
              level={5}
              style={{
                margin: 0,
              }}
            >
              {title}
            </Typography.Title>
          </div>
          <div>
            <p className="text-gray-500">{remark}</p>
          </div>
          <div className="flex items-center">
            <img
              className="w-10 h-10 rounded-full object-center object-cover"
              src={createdBy?.avatar}
              alt="Avatar"
            />
            <div className="text-sm pl-4">
              <span className="text-gray-500 block leading-1">
                {createdBy?.username}
              </span>
              <span className="text-gray-500 block leading-1">
                {DateUtils.getFormattedDate(createdAt)}
              </span>
            </div>
          </div>

          {/* <div className="flex items-center justify-end">
          <div className="text-sm pr-4 text-end">
            <span className="text-gray-900 block leading-1">{author.username}</span>
            <span className="text-gray-400 block leading-1">Aug 18 2022</span>
          </div>
          <img
            className="w-10 h-10 rounded-full object-center object-cover"
            src="https://mobimg.b-cdn.net/v3/fetch/15/151b6fece4f86816128550113007bfa2.jpeg"
            alt="Avatar"
          />
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default PostCard;

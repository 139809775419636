import React, { useEffect } from "react";
import { Row, Col, Spin, Button, theme } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "../components/PageContainer";
import { dashboardSlice } from "../ducks/dashboardDuck/dashboardDuck";
import IReactState from "../types/ReactState";
import { reportSlice } from "../ducks/reportDuck/reportDuck";

const ReportStats = () => {
  const dispatch = useDispatch();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    dispatch(reportSlice.actions.fetchReportsAction({ data: false }));
  }, [dispatch]);

  const { reports, fetchReportsLoading, fetchReportsError } = useSelector(
    (state: IReactState) => state.reportState,
  );

  const tryAgain = () => {
    dispatch(reportSlice.actions.fetchReportsAction({ data: false }));
  };

  const brokenImagesReports = reports.filter(
    (report) => report.reportType === "IMAGES",
  );

  const brokenLinksReports = reports.filter(
    (report) => report.reportType === "IMAGES",
  );

  const totalReports = brokenImagesReports.length + brokenLinksReports.length;

  if (fetchReportsError) {
    return (
      <div>
        <h3 className="text-lg">Reports</h3>
        <Row justify="center">
          <Col className="text-center p-2">
            <div className="text-gray-600">Failed To Fetch Reports</div>
            <Button type="link" onClick={tryAgain}>
              Try Again
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
  return (
    <div>
      <h3 className="text-lg">Reports</h3>
      <Row gutter={[20, 20]}>
        <Col flex="auto">
          <div
            className="text-center p-2 shadow-lg rounded-lg"
            style={{
              background: colorBgContainer,
            }}
          >
            <h4 className="text-rose-600 text-lg">
              {fetchReportsLoading ? <Spin /> : totalReports}
            </h4>
            <h5>Total Reports</h5>
          </div>
        </Col>
        <Col flex="auto">
          <div
            className="text-center p-2 shadow-lg rounded-lg"
            style={{
              background: colorBgContainer,
            }}
          >
            <h4 className="text-rose-600 text-lg">
              {fetchReportsLoading ? <Spin /> : brokenImagesReports.length}
            </h4>
            <h5>Broken Images</h5>
          </div>
        </Col>
        <Col flex="auto">
          <div
            className="text-center p-2 shadow-lg rounded-lg"
            style={{
              background: colorBgContainer,
            }}
          >
            <h4 className="text-rose-600 text-lg">
              {fetchReportsLoading ? <Spin /> : brokenLinksReports.length}
            </h4>
            <h5>Broken Links</h5>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const PageViewStats = () => {
  const dispatch = useDispatch();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    dispatch(dashboardSlice.actions.fetchPageViewStatsAction());
  }, [dispatch]);

  const tryAgain = () => {
    dispatch(dashboardSlice.actions.fetchPageViewStatsAction());
  };

  const { pageViewsStats, fetchPageViewStatsLoading, fetchPageViewStatsError } =
    useSelector((state: IReactState) => state.dashboardState);

  const getPageViewCounts = (type: string) => {
    if (pageViewsStats) {
      const { pageViewStats } = pageViewsStats;
      return pageViewStats.find(({ pageType }) => pageType === type);
    }
    return null;
  };

  if (fetchPageViewStatsError) {
    return (
      <div>
        <h3 className="text-lg">Page View Reports</h3>
        <Row justify="center">
          <Col className="text-center p-2">
            <div className="text-gray-600">Failed To Fetch Reports</div>
            <Button type="link" onClick={tryAgain}>
              Try Again
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  const View = ({ type }: { type: string }) => {
    const viewCounts = getPageViewCounts(type);
    let heading = "";
    switch (type) {
      case "HOME":
        heading = "Home Page";
        break;
      case "POST":
        heading = "Post Pages";
        break;
      case "COLLECTION":
        heading = "Collection Pages";
        break;
      case "STUDIO":
        heading = "Studio Pages";
        break;
      case "STARLET":
        heading = "Starlet Pages";
        break;
      case "TAG":
        heading = "Tag Pages";
        break;
    }
    return (
      <div
        className="px-4 py-1 shadow-lg rounded-lg"
        style={{
          background: colorBgContainer,
        }}
      >
        <h4>{heading}</h4>
        <div className="text-center">
          <Row>
            <Col flex="auto">
              <h4 className="text-lg">
                {fetchPageViewStatsLoading ? <Spin /> : viewCounts?.totalViews}
              </h4>
              <h5>Total Views</h5>
            </Col>
            <Col flex="auto">
              <h4 className="text-lg">
                {fetchPageViewStatsLoading ? <Spin /> : viewCounts?.uniqueViews}
              </h4>
              <h5>Unique Views</h5>
            </Col>
          </Row>
        </div>
      </div>
    );
  };
  return (
    <div>
      <h3 className="text-lg">Page View Reports</h3>
      <Row gutter={[20, 20]}>
        <Col md={8}>
          <View type="HOME" />
        </Col>
        <Col md={8}>
          <View type="COLLECTION" />
        </Col>
        <Col md={8}>
          <View type="POST" />
        </Col>
        <Col md={8}>
          <View type="STARLET" />
        </Col>
        <Col md={8}>
          <View type="STUDIO" />
        </Col>
        <Col md={8}>
          <View type="TAG" />
        </Col>
      </Row>
    </div>
  );
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const { dashboardStats } = useSelector(
    (state: IReactState) => state.dashboardState,
  );

  useEffect(() => {
    // dispatch(dashboardSlice.actions.fetchStatsAction());
  }, [dispatch]);

  return (
    <PageContainer title="Dashboard">
      <Row justify="center">
        <Col span={18} className="text-white">
          <ReportStats />
          <PageViewStats />
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Dashboard;
